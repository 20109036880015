/* eslint-disable import/no-cycle */
/**
 *
 * Developer
 *
 */

import React, { useContext, memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import classNames from 'classnames';
import { isDevEnviroment, injectReducer } from '../../utils';
import { MetaInfoContext, UserDetailsContext } from '../../context';
import { VerticalNavbar } from '../../components';
import {
    APP_LOGS_PATH, DB_STATES_PATH, TICKET_SUMMARY_PATH, DEV_ACTIONS_PATH, LIVENESS_VIDEO_PATH,
    FM_KYC_IMAGE_PATH, LIVENESS_SUMMARY_PATH, LIVENESS_STATUS_PATH, FIT_RULES_PATH, LIVENESS_REVIEW_PATH,
    FACEMATCH_REVIEW_PATH, NAMEMATCH_REVIEW_PATH, WEALTH_OPS_PATH, CUSTOMER_DETAILS_PATH, LIVENESS_AND_FACEMATCH_REVIEW_PATH,
    CX_RISK_REVIEW_PATH, USER_RISK_REVIEW_PATH, US_STOCKS_FACEMATCH_REVIEW_PATH, RISK_CASE_USER_PATH, RISK_CASE_TXN_PATH,
    RISK_RULE_PATH, RISK_CASE_SEARCH_PATH, DEVELOPER_BANKING_PROFILE_PATH, DEVELOPER_BANKING_SAVINGS_ACCOUNT_PATH,
    ESCALATION_REVIEW_PATH,
    MULTI_QUEUE_REVIEW_PATH,
    NR_MANUAL_REVIEW_PATH,
} from '../App/routes';

import AppLogs from '../AppLogs';
import DBStates from '../DBStates';
import TicketSummary from '../TicketSummary';
import DevActions from '../DevActions';
import NROps from '../NROps';
import LivenessSummary from '../LivenessSummary';
import LivenessVideo from '../LivenessVideo';
import FacematchKYCImage from '../FacematchKYCImage';
import LivenessStatus from '../LivenessStatus';
import LivenessReview from '../LivenessReview';
import LivenessReviewV2 from '../LivenessReviewV2';
import FacematchReview from '../FacematchReview';
import FacematchReviewV2 from '../FacematchReviewV2';
import NamematchReview from '../NamematchReview';
import WealthOps from '../WealthOps';
import CustomerDetails from '../CustomerDetails';
import LivenessAndFacematchReview from '../LivenessAndFacematchReview';
import CxRiskReview from '../CxRiskReview';
import UserRiskReview from '../UserRiskReview';
import TransactionQueue from '../TransactionQueue';
import USStocksOps from '../USStocksOps';
import RiskRule from '../RiskRule';
import CaseSearch from '../CaseSearch';
import UserDetailsWrapperForDeveloper from './UserDetailsWrapperForDeveloper';

import { getMenuStructure } from './SideNavbar/utils';
import { DEVELOPER_KEY } from './constants';

import reducer from './reducer';
import './style.scss';

const Developer = (props) => {
    const userInfo = useContext(UserDetailsContext);
    const metaInfo = useContext(MetaInfoContext);

    const {
        basicInfo: {
            accessLevelInfo: {
                isDeveloper, isViewLivenessVideo, isRiskOps: isRiskOpsAgent, isFitAdmin, isRecurringPaymentDev, isWealthOps,
                isRiskOpsAdmin, isRewardsAdmin, isDataOps, isJumpOps, isJumpSensitiveVendorResponse, isUsStocksOps, isKycAnalyst, isRiskOpsTxn,
                isFinanceAdmin, isRiskEng, isUserOutcall, isKycAgentAdmin, isDataRetriever, isSalaryAdmin, isSegmentationAdmin,
                isUsstocksSensitiveData, isCxSupportLlmTester, isStockGuardianLendingOps, isStockGuardianDeveloper, isEscalations,
            },
        },
    } = userInfo;
    const isRiskOps = isRiskOpsAgent || isRiskOpsAdmin;

    const {
        componentVersions: {
            livenessReview: livenessReviewVersion,
            facematchReview: facematchReviewVersion,
            banners: bannersVersion,
        },
    } = metaInfo;

    // eslint-disable-next-line arrow-body-style
    const renderRoutes = () => {
        return (
            <Switch>
                <Route
                    path={APP_LOGS_PATH}
                    render={() => {
                        if (isRiskOps || isWealthOps) {
                            return <Redirect to={{ pathname: TICKET_SUMMARY_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <AppLogs />;
                    }}
                />
                <Route
                    path={DB_STATES_PATH}
                    render={() => {
                        if (isRiskOpsTxn) {
                            return <Redirect to={{ pathname: RISK_CASE_TXN_PATH }} />;
                        }

                        if (isKycAnalyst) {
                            return <Redirect to={{ pathname: RISK_CASE_USER_PATH }} />;
                        }

                        if (isRiskOps || isWealthOps) {
                            return <Redirect to={{ pathname: TICKET_SUMMARY_PATH }} />;
                        }

                        if (isRewardsAdmin || isDataOps || isKycAgentAdmin || isJumpOps || isJumpSensitiveVendorResponse || isFinanceAdmin
                            || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester
                            || isStockGuardianLendingOps || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        if (isUsStocksOps) {
                            return <Redirect to={{ pathname: US_STOCKS_FACEMATCH_REVIEW_PATH }} />;
                        }

                        if (isUserOutcall) {
                            return <Redirect to={{ pathname: RISK_CASE_TXN_PATH }} />;
                        }

                        return <DBStates />;
                    }}
                />
                <Route
                    path={TICKET_SUMMARY_PATH}
                    render={() => {
                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <TicketSummary />;
                    }}
                />
                <Route
                    path={DEV_ACTIONS_PATH}
                    render={() => <DevActions containerKey='developer' />}
                />
                <Route
                    path={NR_MANUAL_REVIEW_PATH}
                    render={() => {
                        if (!isRiskOps) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }
                        return <NROps />;
                    }}
                />
                <Route
                    path={LIVENESS_SUMMARY_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <LivenessSummary />;
                    }}
                />
                <Route
                    path={CUSTOMER_DETAILS_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <CustomerDetails />;
                    }}
                />
                <Route
                    path={LIVENESS_VIDEO_PATH}
                    render={() => {
                        if (isDeveloper || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <LivenessVideo />;
                    }}
                />
                <Route
                    path={FM_KYC_IMAGE_PATH}
                    render={() => {
                        if (isDeveloper || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <FacematchKYCImage />;
                    }}
                />
                <Route
                    path={LIVENESS_STATUS_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <LivenessStatus />;
                    }}
                />
                <Route
                    path={FIT_RULES_PATH}
                    render={() => {
                        if (isViewLivenessVideo || isRiskOps || isWealthOps) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <DBStates />;
                    }}
                />
                <Route
                    path={LIVENESS_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (livenessReviewVersion === 2) {
                            return <LivenessReviewV2 />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <LivenessReview />;
                    }}
                />
                <Route
                    path={FACEMATCH_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (facematchReviewVersion === 2) {
                            return <FacematchReviewV2 />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin
                            || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
                            || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <FacematchReview />;
                    }}
                />
                <Route
                    path={NAMEMATCH_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin
                            || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
                            || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <NamematchReview />;
                    }}
                />
                <Route
                    path={WEALTH_OPS_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isRiskOps) {
                            return <Redirect to={{ pathname: TICKET_SUMMARY_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <WealthOps />;
                    }}
                />
                <Route
                    path={LIVENESS_AND_FACEMATCH_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <LivenessAndFacematchReview />;
                    }}
                />
                <Route
                    path={CX_RISK_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
                             || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <CxRiskReview />;
                    }}
                />
                <Route
                    path={USER_RISK_REVIEW_PATH}
                    render={() => {
                        if (isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev) {
                            return <Redirect to={{ pathname: DB_STATES_PATH }} />;
                        }

                        if (isDataOps || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin
                            || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
                            || isStockGuardianDeveloper || isEscalations) {
                            return <Redirect to={{ pathname: DEV_ACTIONS_PATH }} />;
                        }

                        return <UserRiskReview />;
                    }}
                />
                <Route
                    path={US_STOCKS_FACEMATCH_REVIEW_PATH}
                    render={() => {
                        if (!isUsStocksOps) {
                            return null;
                        }

                        return <USStocksOps />;
                    }}
                />
                <Route
                    path={RISK_CASE_TXN_PATH}
                    render={() => {
                        if (isKycAnalyst) {
                            return <Redirect to={{ pathname: RISK_CASE_USER_PATH }} />;
                        }

                        return <TransactionQueue listType='riskTxnQueue' />;
                    }}
                />
                <Route
                    path={RISK_CASE_USER_PATH}
                    render={() => {
                        if (isRiskOpsTxn) {
                            return <Redirect to={{ pathname: RISK_CASE_TXN_PATH }} />;
                        }

                        return <TransactionQueue listType='userRisk' />;
                    }}
                />
                <Route
                    path={RISK_CASE_SEARCH_PATH}
                    render={() => <CaseSearch />}
                />
                <Route
                    path={RISK_RULE_PATH}
                    render={() => <RiskRule />}
                />
                <Route
                    path={ESCALATION_REVIEW_PATH}
                    render={() => {
                        if (isRiskOpsTxn) {
                            return <Redirect to={{ pathname: RISK_CASE_TXN_PATH }} />;
                        }

                        return <TransactionQueue listType='escalations' />;
                    }}
                />
                <Route
                    path={MULTI_QUEUE_REVIEW_PATH}
                    render={() => {
                        if (isRiskOpsTxn) {
                            return <Redirect to={{ pathname: RISK_CASE_TXN_PATH }} />;
                        }

                        return <TransactionQueue listType='multi' />;
                    }}
                />
                <Route
                    path={DEVELOPER_BANKING_PROFILE_PATH}
                >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <UserDetailsWrapperForDeveloper {...props} isProfile />
                </Route>
                <Route
                    path={DEVELOPER_BANKING_SAVINGS_ACCOUNT_PATH}
                >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <UserDetailsWrapperForDeveloper {...props} isOnboarding />
                </Route>
                {/* in case of unmatched url, redirect the user to db states page */}
                <Redirect to={{ pathname: DB_STATES_PATH }} />
            </Switch>
        );
    };

    return (
        <div
            className={classNames(isDevEnviroment() ? {
                'devs-cr bg-np': bannersVersion === 1,
                'devs-v2-cr bg-np': bannersVersion === 2,
            } : {
                'devs-cr bg': bannersVersion === 1,
                'devs-v2-cr bg': bannersVersion === 2,
            })}
        >
            <VerticalNavbar
                navItems={getMenuStructure({
                    isDeveloper,
                    isViewLivenessVideo,
                    isRiskOps,
                    isFitAdmin,
                    isRecurringPaymentDev,
                    isWealthOps,
                    isRewardsAdmin,
                    isDataOps,
                    isJumpOps,
                    isJumpSensitiveVendorResponse,
                    isUsStocksOps,
                    isKycAnalyst,
                    isRiskOpsTxn,
                    isFinanceAdmin,
                    isRiskOpsAdmin,
                    isRiskEng,
                    isUserOutcall,
                    isKycAgentAdmin,
                    isDataRetriever,
                    isSalaryAdmin,
                    isSegmentationAdmin,
                    isUsstocksSensitiveData,
                    isCxSupportLlmTester,
                    isStockGuardianLendingOps,
                    isStockGuardianDeveloper,
                    isEscalations,
                })}
            />

            <div className={classNames({
                'devs-cc': bannersVersion === 1,
                'devs-v2-cc': bannersVersion === 2,
            })}
            >
                {renderRoutes()}
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: DEVELOPER_KEY, reducer });

export default compose(
    withReducer,
)(memo(Developer));
