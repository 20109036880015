/**
 * @file RenderComponent - Component to render the a table or key value pair component
 */

import React, { useEffect, useState } from 'react';

import { Button, KVPairInfo, Table } from '../../../components';
import { BUTTON_THEMES } from '../constants';

const RenderComponent = (props) => {
    const {
        component, renderCustomTableActionsColumn, tableErrLabel, handleTableActionClick,
    } = props;

    const [hasActionsColumn, setHasActionsColumn] = useState(false);

    const { table, key_value_component: section, details } = component || {};
    const {
        columnData, rowData, tableName, actions: tableActions,
    } = table || {};
    const { title, label_values: labelValues } = section || {};

    useEffect(() => {
        if (table) {
            if (tableActions && tableActions?.length > 0) {
                setHasActionsColumn(true);
            } else {
                const hasActions = rowData?.some((row) => row?.actions?.length > 0);
                setHasActionsColumn(hasActions);
            }
        }
    }, [table]);

    const renderActions = (item) => {
        const { meta, actions: rowActions } = item;

        const actions = rowActions && rowActions?.length > 0 ? rowActions : tableActions;

        if (!handleTableActionClick || !actions || !actions?.length) return null;

        return (
            <div className='frfscWrapper'>
                {
                    actions.map((action) => (
                        <Button
                            v2
                            primary={action.display_theme === BUTTON_THEMES.primary}
                            secondary={action.display_theme === BUTTON_THEMES.secondary}
                            tertiary={action.display_theme === BUTTON_THEMES.tertiary}
                            disabled={action.is_disabled}
                            label={action.label}
                            onClick={() => handleTableActionClick(action)(meta)}
                        />
                    ))
                }
            </div>
        );
    };

    switch (details) {
        case 'key_value_component': {
            return (
                <React.Fragment>
                    <div className='pal-th'>{title}</div>
                    <KVPairInfo data={labelValues} v2 valuesHaveHtml />
                </React.Fragment>
            );
        }
        case 'table': {
            const renderTableActionsColumn = renderCustomTableActionsColumn || renderActions;
            return (
                <React.Fragment>
                    <div className='pal-th'>{tableName}</div>
                    <Table
                        rowDataVersion={2}
                        renderActionableColumn={hasActionsColumn ? renderTableActionsColumn : null}
                        labelData={columnData}
                        contentData={rowData}
                        errLabel={tableErrLabel}
                        extClasses={{
                            container: 'mb-60',
                        }}
                    />
                </React.Fragment>
            );
        }
        default: {
            return null;
        }
    }
};

export default RenderComponent;
