/**
 *
 * FinalStep - Component to handle the Final Step type and render the final issue result
 *
 */

import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';

import MedalSVG from '../../../assets/images/svgs/medal.svg';
import htmlSanitization from '../../../utils/htmlSanitisation';

import { renderDetails } from '../constants';

const FinalStep = (props) => {
    const { step } = props;
    const {
        resolutionTitle, resolutionDescription, productCategory, solution,
    } = step || {};

    const { status } = productCategory || {};

    // Classnames for different statuses
    const statusClasses = classNames(
        'sop-step-status',
        { 'sop-step-status-open': status?.label === 'Open' },
        { 'sop-step-status-closed': status?.label === 'Closed' },
        { 'sop-step-status-resolved': status?.label === 'Resolved' },
        { 'sop-step-status-wai-on-cus': status?.label === 'Waiting on Customer' },
        { 'sop-step-status-esc-to-ven': status?.label === 'Escalated to Vendor' },
    );

    return (
        <div className='frfsWrapper my-30'>
            <img src={MedalSVG} alt='Medal' className='mr-16' />
            <div>
                <div className={statusClasses}>{status?.label}</div>
                <div className='sop-step-title'>{resolutionTitle}</div>
                <div className='sop-step-des'>
                    {solution ? <div dangerouslySetInnerHTML={{ __html: htmlSanitization(solution) }} /> : resolutionDescription }
                </div>
                {
                    productCategory && renderDetails(productCategory).map((item) => (
                        <div className='my-15'>
                            <div className='sop-step-details-lbl'>{item.title}</div>
                            <div className='sop-step-details-val'>{item.value || '-'}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export const FinalStepPropTypes = {
    title: PropTypes.string,
    resolutionTitle: PropTypes.string,
    resolutionDescription: PropTypes.string,
    productCategory: PropTypes.shape({
        L1: PropTypes.string,
        L2: PropTypes.string,
        L3: PropTypes.string,
        agent: PropTypes.string,
        group: PropTypes.string,
        status: PropTypes.string,
    }),
    nodeId: PropTypes.string,
};

FinalStep.propTypes = {
    step: PropTypes.shape(FinalStepPropTypes).isRequired,
};

export default FinalStep;
