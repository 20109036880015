/**
 * @file Manages the selector functions forPre Approved Loans component
 */

import { createSelector } from 'reselect';

import { LOAN_AGAINST_MUTUAL_FUNDS_KEY, PRE_APPROVED_LOANS_KEY, LOANS_KEY } from './constants';

const selectUserInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, PRE_APPROVED_LOANS_KEY, 'userInfo']),
    (substate) => substate.toJS(),
);

const selectLoansInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, PRE_APPROVED_LOANS_KEY, 'loanInfo']),
    (substate) => substate.toJS(),
);

const selectLoansAdditionalInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, PRE_APPROVED_LOANS_KEY, 'additionalLoanInfo']),
    (substate) => substate.toJS(),
);

const selectLAMFUserInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo']),
    (substate) => substate.toJS(),
);

const selectLAMFLoansInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo']),
    (substate) => substate.toJS(),
);

const selectLAMFLoansAdditionalInfo = createSelector(
    (state) => state.getIn([LOANS_KEY, LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails']),
    (substate) => substate.toJS(),
);

export {
    selectUserInfo,
    selectLoansInfo,
    selectLAMFUserInfo,
    selectLAMFLoansInfo,
    selectLAMFLoansAdditionalInfo,
    selectLoansAdditionalInfo,
};
