/**
 * @file Manages the reducer functions for Loans Out Call component
 */

import { fromJS } from 'immutable';

import {
    GET_LOANS_OUT_CALL_LOANS_DETAILS, SET_LOANS_OUT_CALL_LOANS_DETAILS, ERR_LOANS_OUT_CALL_LOANS_DETAILS,
    GET_LOANS_OUT_CALL_ADDITIONAL_INFO, SET_LOANS_OUT_CALL_ADDITIONAL_INFO, ERR_LOANS_OUT_CALL_ADDITIONAL_INFO,
    GET_LOANS_OUT_CALL_USER_DETAILS, SET_LOANS_OUT_CALL_USER_DETAILS, ERR_LOANS_OUT_CALL_USER_DETAILS,
    SET_LOANS_OUT_CALL_TICKET_ID, SET_LOANS_OUT_CALL_TICKETS, GET_LOANS_OUT_CALL_TICKETS, ERR_LOANS_OUT_CALL_TICKETS,
    GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS, SET_LOANS_OUT_CALL_USER_PROFILE_DETAILS, ERR_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
    GET_LOANS_OUT_CALL_USER_TICKET_HISTORY, SET_LOANS_OUT_CALL_USER_TICKET_HISTORY, ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY,
} from './constants';

const initialState = fromJS({
    userInfo: {},
    loanDetails: {},
    loanAdditionalInfo: {},
    ticketId: '',
    ticketsTable: {},
    userTicketHistory: {},
    userProfileInfo: {},
    loading: false,
    err: '',
});

function LoansOutCallReducer(state = initialState, action = '') {
    switch (action.type) {
        // Getter api changes
        case GET_LOANS_OUT_CALL_USER_TICKET_HISTORY:
        case GET_LOANS_OUT_CALL_TICKETS:
        case GET_LOANS_OUT_CALL_LOANS_DETAILS:
        case GET_LOANS_OUT_CALL_ADDITIONAL_INFO:
        case GET_LOANS_OUT_CALL_USER_DETAILS:
        case GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS: {
            const { key: keyArg = 'data', screenKey, clearData } = action.data || {};
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values[keyArg] = null;
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn([keyArg], null);
        }

        // Setter api merge changes
        case SET_LOANS_OUT_CALL_USER_TICKET_HISTORY:
        case SET_LOANS_OUT_CALL_TICKETS:
        case SET_LOANS_OUT_CALL_LOANS_DETAILS:
        case SET_LOANS_OUT_CALL_ADDITIONAL_INFO:
        case SET_LOANS_OUT_CALL_USER_DETAILS:
        case SET_LOANS_OUT_CALL_USER_PROFILE_DETAILS: {
            const { screenKey, key: keyArg, value } = action.data || {};
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }
            return state
                .setIn([keyArg], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        // Err api Changes
        case ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY:
        case ERR_LOANS_OUT_CALL_TICKETS:
        case ERR_LOANS_OUT_CALL_LOANS_DETAILS:
        case ERR_LOANS_OUT_CALL_ADDITIONAL_INFO:
        case ERR_LOANS_OUT_CALL_USER_PROFILE_DETAILS:
        case ERR_LOANS_OUT_CALL_USER_DETAILS: {
            const { screenKey, err } = action.data || {};
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['data'], null);
        }
        // Setter non api changes
        case SET_LOANS_OUT_CALL_TICKET_ID: {
            const { data, key } = action.data || {};
            if (!Array.isArray(key)) {
                return state
                    .setIn([key], data);
            }
            return state
                .setIn(key, data);
        }

        default:
            return state;
    }
}

export default LoansOutCallReducer;
