/**
 * @file Manages the reducer state & function for Tiering component
 */

import { fromJS } from 'immutable';

import {
    GET_TIERING_DETAILS, SET_TIERING_DETAILS, ERR_TIERING_DETAILS,
    SET_TIERING_GRACE, ERR_TIERING_GRACE, SET_TIERING_COOLOFF, ERR_TIERING_COOLOFF,
    GET_TIERING_SENSITIVE_DETAILS, SET_TIERING_SENSITIVE_DETAILS, ERR_TIERING_SENSITIVE_DETAILS,
    GET_USER_ABUSE_HISTORY, SET_USER_ABUSE_HISTORY, ERR_USER_ABUSE_HISTORY,
} from './constants';

const initialState = fromJS({
    tieringInfo: {},
    userAbuseHistory: {
        loading: false,
    },
});

// eslint-disable-next-line default-param-last
function tieringReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TIERING_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], true)
                .setIn(['tieringInfo', ticketId, 'err'], '');
        }

        case SET_TIERING_DETAILS: {
            const { ticketId, tieringInfo } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], false)
                .setIn(['tieringInfo', ticketId, 'hasData'], true)
                .setIn(['tieringInfo', ticketId, 'data'], fromJS(tieringInfo));
        }

        case ERR_TIERING_DETAILS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], false)
                .setIn(['tieringInfo', ticketId, 'hasData'], false)
                .setIn(['tieringInfo', ticketId, 'data'], fromJS({}))
                .setIn(['tieringInfo', ticketId, 'err'], err);
        }

        case GET_USER_ABUSE_HISTORY: {
            const { ticketId } = action.data;

            return state
                .setIn(['userAbuseHistory', ticketId, 'loading'], true)
                .setIn(['userAbuseHistory', ticketId, 'err'], '');
        }

        case SET_USER_ABUSE_HISTORY: {
            const { ticketId, tierToEligibilityStatus } = action.data;
            return state
                .setIn(['userAbuseHistory', ticketId, 'loading'], false)
                .setIn(['userAbuseHistory', ticketId, 'hasData'], true)
                .setIn(['userAbuseHistory', ticketId, 'data'], fromJS(tierToEligibilityStatus));
        }

        case ERR_USER_ABUSE_HISTORY: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userAbuseHistory', ticketId, 'loading'], false)
                .setIn(['userAbuseHistory', ticketId, 'hasData'], false)
                .setIn(['userAbuseHistory', ticketId, 'err'], err);
        }

        case SET_TIERING_GRACE: {
            const { tieringInfo } = action.data;

            return state
                .setIn(['tieringInfo', 'tieringOverrideGrace'], fromJS(tieringInfo));
        }

        case ERR_TIERING_GRACE: {
            const { err } = action.data;

            return state
                .setIn(['tieringInfo', 'loading'], false)
                .setIn(['tieringInfo', 'err'], err);
        }

        case SET_TIERING_COOLOFF: {
            const { tieringInfo } = action.data;

            return state
                .setIn(['tieringInfo', 'tieringOverrideCoolOff'], fromJS(tieringInfo));
        }

        case ERR_TIERING_COOLOFF: {
            const { err } = action.data;

            return state
                .setIn(['tieringInfo', 'loading'], false)
                .setIn(['tieringInfo', 'err'], err);
        }

        case GET_TIERING_SENSITIVE_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], true)
                .setIn(['tieringInfo', ticketId, 'err'], '');
        }

        case SET_TIERING_SENSITIVE_DETAILS: {
            const { ticketId, tieringInfo } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], false)
                .setIn(['tieringInfo', ticketId, 'data', 'tierPlanName'], tieringInfo.tierPlanName)
                .setIn(['tieringInfo', ticketId, 'data', 'movementHistories'], tieringInfo.movementHistories);
        }

        case ERR_TIERING_SENSITIVE_DETAILS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['tieringInfo', ticketId, 'loading'], false)
                .setIn(['tieringInfo', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default tieringReducer;
