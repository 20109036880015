/**
 * @file Manages the action functions for Tiering component
 */

import {
    GET_TIERING_DETAILS, SET_TIERING_GRACE, SET_TIERING_COOLOFF, GET_TIERING_SENSITIVE_DETAILS, GET_USER_ABUSE_HISTORY,
} from './constants';

export const getTieringDetails = (data) => ({
    type: GET_TIERING_DETAILS,
    data,
});

export const getTieringSensitiveDetails = (data) => ({
    type: GET_TIERING_SENSITIVE_DETAILS,
    data,
});

export const setTieringGrace = (data) => ({
    type: SET_TIERING_GRACE,
    data,
});

export const setTieringCoolOff = (data) => ({
    type: SET_TIERING_COOLOFF,
    data,
});

export const getUserAbuseHistory = (data) => ({
    type: GET_USER_ABUSE_HISTORY,
    data,
});
