/**
 * @file Manages the utility functions for Tiering component
 */

const getTieringList = (data) => ([
    // {
    //     label: 'Current Plan',
    //     value: data.tierPlanName,
    // },
    {
        label: 'Is User in Grace period',
        value: data.isUserInGrace,
    },
    {
        label: 'Grace period till',
        value: data.gracePeriodTill,
    },
    {
        label: 'Is user in CoolOff period',
        value: data.isUserInCoolOff,
    },
    {
        label: 'CoolOff period till',
        value: data.coolOffPeriodTill,
    },
]);

const getAbuseHistory = (data) => ([
    {
        label: 'Tier Name',
        value: data.tier_name,
    },
    {
        label: 'Is Eligible',
        value: data.is_eligible,
    },
]);

const getPlanName = (data) => ([
    {
        label: 'Current Plan',
        value: data.tierPlanName,
    },
]);

/**
 * Function returns object with date's upper limit, lower limit, from & to date
 * for date picker to get initialised with these values
 *
 * @return {object} object with date's upper limit, lower limit & value
 */
const getDateRange = () => {
    const range = 1296000000;// 15 days is 1296000000 milliseconds
    const lowerLimitToDate = new Date();

    const upperLimitToDate = new Date(Date.parse(lowerLimitToDate) + range); // Current Date + 15 Days
    return {
        lowerLimit: lowerLimitToDate,
        upperLimit: upperLimitToDate,
        value: '',
    };
};

export {
    getTieringList,
    getPlanName,
    getDateRange,
    getAbuseHistory,
};
