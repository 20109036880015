// key to be used for reducers & selectors for pre approved loans
const LOANS_OUT_CALL_KEY = 'loansOutCall';

const customModalStyles = {
    content: {
        width: 1100,
        height: 500,
        overflow: 'scroll',
    },
};

const BUTTON_THEMES = {
    primary: 'DISPLAY_THEME_PRIMARY',
    secondary: 'DISPLAY_THEME_SECONDARY',
    tertiary: 'DISPLAY_THEME_TERTIARY',
};

// loans details
export const GET_LOANS_OUT_CALL_LOANS_DETAILS = `app/${LOANS_OUT_CALL_KEY}/GET_LOANS_INFO`;
export const SET_LOANS_OUT_CALL_LOANS_DETAILS = `app/${LOANS_OUT_CALL_KEY}/SET_LOANS_INFO`;
export const ERR_LOANS_OUT_CALL_LOANS_DETAILS = `app/${LOANS_OUT_CALL_KEY}/ERR_LOANS_INFO`;

// user details
export const GET_LOANS_OUT_CALL_USER_DETAILS = `app/${LOANS_OUT_CALL_KEY}/GET_USER_INFO`;
export const SET_LOANS_OUT_CALL_USER_DETAILS = `app/${LOANS_OUT_CALL_KEY}/SET_USER_INFO`;
export const ERR_LOANS_OUT_CALL_USER_DETAILS = `app/${LOANS_OUT_CALL_KEY}/ERR_USER_INFO`;

// loans additional info
export const GET_LOANS_OUT_CALL_ADDITIONAL_INFO = `app/${LOANS_OUT_CALL_KEY}/GET_LOANS_ADDITIONAL_INFO`;
export const SET_LOANS_OUT_CALL_ADDITIONAL_INFO = `app/${LOANS_OUT_CALL_KEY}/SET_LOANS_ADDITIONAL_INFO`;
export const ERR_LOANS_OUT_CALL_ADDITIONAL_INFO = `app/${LOANS_OUT_CALL_KEY}/ERR_LOANS_ADDITIONAL_INFO`;

// set ticket id
export const SET_LOANS_OUT_CALL_TICKET_ID = `app/${LOANS_OUT_CALL_KEY}/SET_TICKET_ID`;

// get loans outcall tickets
export const GET_LOANS_OUT_CALL_TICKETS = `app/${LOANS_OUT_CALL_KEY}/GET_LOANS_OUT_CALL_TICKETS`;
export const SET_LOANS_OUT_CALL_TICKETS = `app/${LOANS_OUT_CALL_KEY}/SET_LOANS_OUT_CALL_TICKETS`;
export const ERR_LOANS_OUT_CALL_TICKETS = `app/${LOANS_OUT_CALL_KEY}/ERR_LOANS_OUT_CALL_TICKETS`;

// user profile info
export const GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS = `app/${LOANS_OUT_CALL_KEY}/GET_USER_PROFILE_INFO`;
export const SET_LOANS_OUT_CALL_USER_PROFILE_DETAILS = `app/${LOANS_OUT_CALL_KEY}/SET_USER_PROFILE_INFO`;
export const ERR_LOANS_OUT_CALL_USER_PROFILE_DETAILS = `app/${LOANS_OUT_CALL_KEY}/ERR_USER_PROFILE_INFO`;

// get user ticket history
export const GET_LOANS_OUT_CALL_USER_TICKET_HISTORY = `app/${LOANS_OUT_CALL_KEY}/GET_LOANS_OUT_CALL_USER_TICKET_HISTORY`;
export const SET_LOANS_OUT_CALL_USER_TICKET_HISTORY = `app/${LOANS_OUT_CALL_KEY}/SET_LOANS_OUT_CALL_USER_TICKET_HISTORY`;
export const ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY = `app/${LOANS_OUT_CALL_KEY}/ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY`;

const LOANS_OUT_CALL_DEV_ACTION_TYPES = ['UPDATE_LOANS_OUTCALL_TICKET'];

const getLoansOutCallPreFilledValuesObj = (ticketId) => ({
    mergeKey: 'label',
    paramList: [
        { label: 'Ticket ID', value: ticketId },
    ],
});

export {
    LOANS_OUT_CALL_KEY, customModalStyles, BUTTON_THEMES, LOANS_OUT_CALL_DEV_ACTION_TYPES, getLoansOutCallPreFilledValuesObj,
};
