/**
 * @file Manages the selector functions for Loans Out Call component
 */

import { createSelector } from 'reselect';

import { LOANS_OUT_CALL_KEY } from './constants';

const selectUserInfo = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'userInfo']),
    (substate) => substate?.toJS(),
);

const selectLoansInfo = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'loanDetails']),
    (substate) => substate?.toJS(),
);

const selectLoansAdditionalInfo = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'loanAdditionalInfo']),
    (substate) => substate?.toJS(),
);

const selectLoansOutCallInfo = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY]),
    (substate) => substate?.toJS(),
);

const selectLoansOutCallTicketId = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'ticketId']),
    (substate) => substate,
);

const selectLoansOutCallUserTicketHistory = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'userTicketHistory']),
    (substate) => substate?.toJS(),
);

const selectUserProfileInfo = createSelector(
    (state) => state.getIn([LOANS_OUT_CALL_KEY, 'userProfileInfo']),
    (substate) => substate.toJS(),
);

export {
    selectLoansInfo,
    selectLoansAdditionalInfo,
    selectUserInfo,
    selectLoansOutCallInfo,
    selectLoansOutCallTicketId,
    selectUserProfileInfo,
    selectLoansOutCallUserTicketHistory,
};
