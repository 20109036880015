import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';

import { Button } from '../../../components';
import { UserDetailsContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import {
    getEndVKYCCall, setVKYCQuestionAnswers, setCurrentStagePointers, logStageProgress,
    setCallRemarks,
} from '../actions';
import {
    selectVkycData, selectCurrentStage, selectCurrentSubStage, selectFileLocalStoreStatus, selectVKYCConfig,
} from '../selectors';
import { CAMERA_ORIENTATION, VKYC_STAGES, stagePointerPayload } from '../constants';
import RejectionRemarks from '../RejectionRemarks';
import Remarks from '../Remarks';

const BottomOptions = ({ stopRecording }) => {
    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData);
    const [isConfirmed, setIsConfirmed] = useState();
    const localStoreStatus = useSelector(selectFileLocalStoreStatus);
    const vkycConsulConfig = useSelector(selectVKYCConfig);
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const [isAddRemarkVisible, setIsAddRemarkVisible] = useState(true);

    const [rejectionRemarkState, setRejectionRemarkState] = useState({
        visible: false,
        comment: '',
        rejectionReason: '',
    });

    const [remarksVisible, setRemarksVisible] = useState(false);

    const { data, callRemarks, meetings } = vkycData || {};

    const meetingId = data?.meetingId;

    const currentStep = useSelector(selectCurrentStage(meetingId));
    const currentSubStep = useSelector(selectCurrentSubStage(meetingId));

    const { stage_name: stageName, stages: subStagesName } = meetings?.[meetingId]?.stages?.[currentStep] || {};
    const { stage_type: subStageType } = subStagesName?.[currentSubStep] || {};

    const constructPayload = () => {
        const s3Url = vkycData?.data?.recordingUploadURL;
        return {
            meetingId,
            isAccepted: false,
            s3Url,
            screenKey: ['meetings', meetingId, 'endCall'],
            Data: {
                overall_accepted: !!isConfirmed,
                rejection_reason: rejectionRemarkState?.rejectionReason,
                agent_remarks: rejectionRemarkState?.comment,
            },
        };
    };

    const onEndCallClick = (isCompleted = false) => {
        if (localStoreStatus) {
            dispatch(getEndVKYCCall(constructPayload()));
            pushRudderEvent(RudderEvent.VKYC.EndCallCTAClicked, agentInfo.emailId, {
                meetingId,
                stageName: currentStep?.stage_name,
                subStageType: currentSubStep?.stage_type,
            });
        } else {
            setIsConfirmed(!!isCompleted);
            stopRecording();
        }
        setRejectionRemarkState({ ...rejectionRemarkState, visible: false });
    };

    const submitCallRemark = (values = {}) => {
        setRemarksVisible(false);
        dispatch(setCallRemarks({ key: 'callRemarks', data: { ...callRemarks, ...values } }));
    };

    useEffect(() => {
        if (localStoreStatus) {
            dispatch(getEndVKYCCall(constructPayload()));
            setRejectionRemarkState({ ...rejectionRemarkState, visible: false });
            pushRudderEvent(RudderEvent.VKYC.EndCallCTAClicked, agentInfo.emailId, {
                meetingId,
                stageName: currentStep?.stage_name,
                subStageType: currentSubStep?.stage_type,
            });
        }
    }, [localStoreStatus]);

    /**
     *
     *  At each step we are only enabling next button when it is last substep of the stage, along with that we are also
     *  checking if all the required data is captured for the stage.
     *
     * @returns {boolean} isNextStageButtonActive
     *
     */

    const isNextStageButtonActive = () => {
        const stages = vkycData?.meetings?.[meetingId]?.stages;
        const currentStage = path([currentStep], stages);
        const currentMeetingState = vkycData?.meetings?.[meetingId];

        /**
         * it checks if current step is enabled by-default.
         */

        if (Array.isArray(vkycConsulConfig?.defaultEnabledStages)
        && vkycConsulConfig?.defaultEnabledStages.includes(currentStage?.stage_name)) return true;

        /**
         * currentStep === 5 is skipped as , for emirates id capture, last step is not implemented
         */

        if (currentStep !== 5 && currentSubStep + 1 < path(['stages', 'length'], currentStage)) {
            return false;
        }

        let isActive = false;

        let requiredCaptureData;

        switch (currentStage?.stage_name) {
            case VKYC_STAGES.LIVENESS: {
                const questions = path(['stages', currentSubStep, 'question_answer', 'questions'], currentStage);
                const answers = currentMeetingState?.answers;
                if (!answers) {
                    isActive = false;
                    break;
                }
                isActive = questions.reduce((accumulator, question) => {
                    const isOptional = question?.is_optional;
                    const isAccepted = path([question?.question_type, 'accepted'], answers);
                    return accumulator && (isOptional || isAccepted);
                }, true);
                return isActive;
            }

            case VKYC_STAGES.LOCATION: {
                const locationDetails = path(['stages', '0', 'location', 'location_details'], currentStage);
                return !!locationDetails && Array.isArray(locationDetails) && locationDetails.every((question) => question?.is_valid);
            }

            case VKYC_STAGES.FACE_CAPTURE: {
                requiredCaptureData = path(['CAMERA_TYPE_FRONT', 'imageUrl'], currentStage);
                return !!requiredCaptureData;
            }
            case VKYC_STAGES.PAN_CAPTURE:
                requiredCaptureData = path(['CAMERA_TYPE_REAR', 'imageUrl'], currentStage);
                return !!requiredCaptureData;
            case VKYC_STAGES.PASSPORT_CAPTURE:
                requiredCaptureData = {};
                requiredCaptureData.FRONT = path(['stages', '0', 'CAMERA_TYPE_REAR', 'imageUrl'], currentStage);
                requiredCaptureData.REAR = path(['stages', '2', 'CAMERA_TYPE_REAR', 'imageUrl'], currentStage);
                return !!(requiredCaptureData?.FRONT && requiredCaptureData?.REAR);
            case VKYC_STAGES.EMIRATES_ID_CAPTURE:
                requiredCaptureData = {};
                requiredCaptureData.FRONT = path(['stages', '0', 'CAMERA_TYPE_REAR', 'imageUrl'], currentStage);
                requiredCaptureData.REAR = path(['stages', '1', 'CAMERA_TYPE_REAR', 'imageUrl'], currentStage);
                return currentSubStep === 4 && !!(requiredCaptureData?.FRONT && requiredCaptureData?.REAR);
            case VKYC_STAGES.DOCUMENT_VERIFICATION:
                return path(['faceMatchImage'], currentStage);
            default: break;
        }

        return isActive;
    };

    const getSubState = () => {
        const stages = vkycData?.meetings?.[meetingId]?.stages;
        return stages?.[currentStep];
    };

    const onNextStep = () => {
        // const currentStep = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stepNumber;
        const stages = vkycData?.meetings?.[meetingId]?.stages;
        const subStages = stages?.[currentStep]?.stages;
        pushRudderEvent(RudderEvent.VKYC.ConfirmCTAClicked, agentInfo.emailId, {
            meetingId,
            stageName: currentStep?.stage_name,
            subStageType: currentSubStep?.stage_type,
        });
        switch (stages?.[currentStep]?.stage_name) {
            case VKYC_STAGES.LOCATION:
                dispatch(setVKYCQuestionAnswers({
                    questionType: stages[currentStep]?.stages?.[0]?.location?.question_type,
                    accepted: true,
                    meetingId: vkycData?.data?.meetingId,
                }));
                break;
            case VKYC_STAGES.FACE_CAPTURE:
            case VKYC_STAGES.PAN_CAPTURE:
                stages[currentStep]?.stages.forEach((stage) => {
                    if (stage?.image_capture) {
                        dispatch(setVKYCQuestionAnswers({
                            questionType: stage?.image_capture?.question_type,
                            cameraRear: stages[currentStep]?.[CAMERA_ORIENTATION?.back],
                            cameraFront: stages[currentStep]?.[CAMERA_ORIENTATION?.front],
                            accepted: true,
                            meetingId: vkycData?.data?.meetingId,
                        }));
                    }
                });
                break;
            case VKYC_STAGES.PASSPORT_CAPTURE:
            case VKYC_STAGES.EMIRATES_ID_CAPTURE:
                stages[currentStep]?.stages.forEach((stage) => {
                    if (stage?.image_capture || stage?.[CAMERA_ORIENTATION?.back] || stage?.[CAMERA_ORIENTATION?.front]) {
                        dispatch(setVKYCQuestionAnswers({
                            questionType: stage?.image_capture?.question_type,
                            cameraRear: stage?.[CAMERA_ORIENTATION?.back],
                            cameraFront: stage?.[CAMERA_ORIENTATION?.front],
                            accepted: true,
                            meetingId: vkycData?.data?.meetingId,
                        }));
                    }
                });
                break;
            default: break;
        }

        dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStep + 1, 0)));
        dispatch(logStageProgress({
            message: 'Stage completed',
            stageName: stages?.[currentStep]?.stage_name,
            subStageType: subStages?.[currentSubStep]?.stage_type,
            meetingId,
        }));
    };

    return (
        <div className='vkyc-bottom-options'>
            <Button
                secondary
                v2
                label='End Call'
                onClick={() => {
                    setRejectionRemarkState((prev) => ({
                        ...prev, visible: true, rejectionReason: '', comment: '',
                    }));
                }}
                extClasses='vkyc-button-end'
            />
            <Button
                primary
                v2
                label='Confirm'
                onClick={onNextStep}
                // Use here !nextStepEnable to disable the Confirm button
                disabled={!isNextStageButtonActive()}
            />
            {
                isAddRemarkVisible && (
                    <Button
                        secondary
                        v2
                        label='Add Remarks'
                        onClick={() => setRemarksVisible(true)}
                    />
                )
            }
            <RejectionRemarks
                remarkState={rejectionRemarkState}
                rejectionRemarkState={rejectionRemarkState}
                reviewReportAction={onEndCallClick}
                rejectedReasons={getSubState()?.rejection_categories}
                setRemarkState={setRejectionRemarkState}
                loaderFlag={vkycData?.meetings?.[meetingId]?.reports?.loading || vkycData?.loading}
            />
            <Remarks
                visible={remarksVisible}
                meetingId={meetingId}
                currentStep={stageName}
                currentSubStep={subStageType}
                onCancel={() => setRemarksVisible(false)}
                onSubmit={submitCallRemark}
                submitText='Add Remarks'
                setIsAddRemarkVisible={setIsAddRemarkVisible}
            />
        </div>
    );
};

export default BottomOptions;
