/**
 * @file Manages the saga generator functions for Loans Out Call
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    LOANS_OUT_CALL_ASSIGNED_TICKETS, PAL_LOANS_INFO_ENDPOINT, PAL_LOANS_ADDITIONAL_INFO_ENDPOINT, PAL_USER_INFO_ENDPOINT,
    LOANS_OUT_CALL_USER_PROFILE_INFO, LOANS_OUT_CALL_TICKET_HISTORY,
} from '../../api/routes';
import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_LOANS_OUT_CALL_LOANS_DETAILS, GET_LOANS_OUT_CALL_USER_DETAILS, GET_LOANS_OUT_CALL_ADDITIONAL_INFO,
    GET_LOANS_OUT_CALL_TICKETS, GET_LOANS_OUT_CALL_USER_TICKET_HISTORY, GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
} from './constants';
import {
    errLoansAdditionalInfo, errLoansInfo, errLoansOutCallTickets, errLoansOutCallUserTicketHistory, errUserInfo, errUserProfileInfoDetails,
    setLoansAdditionalInfo, setLoansInfo, setLoansOutCallTickets, setLoansOutCallUserTicketHistory, setUserInfo, setUserProfileInfoDetails,
} from './actions';

function* getOutcallUserDetails(action) {
    const {
        ticketId, screenKey,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_USER_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put(setUserInfo({ value: { data: response?.components }, screenKey }));
        } else {
            yield put(errUserInfo({ err: result, screenKey }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errUserInfo({ err, screenKey }));
    }
}
function* getOutcallLoansDetails(action) {
    const {
        ticketId, screenKey,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put(setLoansInfo({ value: { data: response?.components }, screenKey }));
        } else {
            yield put(errLoansInfo({ err: result, screenKey }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errLoansInfo({ err, screenKey }));
    }
}

function* getOutcallLoanAdditionalDetails(action) {
    const {
        ticketId, userId, index, screenKey, meta, resolve, reject,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, meta });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_ADDITIONAL_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            if (resolve) {
                yield call(resolve);
            }
            yield put(setLoansAdditionalInfo({ value: { data: response?.components }, screenKey }));
        } else {
            if (reject) {
                yield call(reject);
            }
            yield put(errLoansAdditionalInfo({ err: result, screenKey }));
        }
    } catch (e) {
        if (reject) {
            yield call(reject);
        }
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errLoansAdditionalInfo({ err, screenKey }));
    }
}

function* getLoansOutCallTickets(action) {
    try {
        const { prevToken, nextToken } = action.data || {};
        const queryString = getQueryStringFromObject({ prevToken, nextToken });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOANS_OUT_CALL_ASSIGNED_TICKETS}?${queryString}`,
        );

        yield put(setLoansOutCallTickets({ key: 'ticketsTable', value: response?.tickets }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errLoansOutCallTickets({ key: 'ticketsTable', err }));
    }
}

function* getOutcallUserProfileInfo(action) {
    const {
        ticketId, screenKey,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOANS_OUT_CALL_USER_PROFILE_INFO}?${queryString}`,
        );

        yield put(setUserProfileInfoDetails({ value: { data: response?.components }, screenKey }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errUserProfileInfoDetails({ err, screenKey }));
    }
}

function* getLoansOutCallUserTicketHistory(action) {
    const { ticketId, screenKey } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOANS_OUT_CALL_TICKET_HISTORY}?${queryString}`,
        );

        yield put(setLoansOutCallUserTicketHistory({ screenKey, value: { data: response?.components } }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errLoansOutCallUserTicketHistory({ screenKey, err }));
    }
}

export default function* loansOutCallSaga() {
    yield all(
        [
            yield takeLatest(GET_LOANS_OUT_CALL_LOANS_DETAILS, getOutcallLoansDetails),
            yield takeLatest(GET_LOANS_OUT_CALL_USER_DETAILS, getOutcallUserDetails),
            yield takeLatest(GET_LOANS_OUT_CALL_ADDITIONAL_INFO, getOutcallLoanAdditionalDetails),
            yield takeLatest(GET_LOANS_OUT_CALL_TICKETS, getLoansOutCallTickets),
            yield takeLatest(GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS, getOutcallUserProfileInfo),
            yield takeLatest(GET_LOANS_OUT_CALL_USER_TICKET_HISTORY, getLoansOutCallUserTicketHistory),
        ],
    );
}
