/**
 * @file Manages the reducer state & functions for Scripts component
 */
import { fromJS } from 'immutable';
import {
    ERR_AVAILABLE_CALLS, ERR_CAPTURE_SCREENSHOT, ERR_CONCLUDE_VKYC_CALL, ERR_END_VKYC_CALL,
    ERR_EXTRACT_DOCUMENT_DETAILS, ERR_GENERATE_VKYC_REPORT, ERR_INITIATE_VKYC_CALL,
    ERR_MATCH_SCORE,
    ERR_ONBOARDING_STAGES,
    ERR_PERFORM_CLIENT_ACTION,
    ERR_UPLOAD_SCREEN_RECORDING,
    GET_CAPTURE_SCREENSHOT, GET_END_VKYC_CALL, GET_EXTRACT_DOCUMENT_DETAILS, GET_GENERATE_VKYC_REPORT, GET_INITIATE_VKYC_CALL,
    GET_MATCH_SCORE,
    GET_ONBOARDING_STAGES,
    GET_PERFORM_CLIENT_ACTION,
    SET_AVAILABLE_CALLS, SET_CAPTURE_SCREENSHOT, SET_CONCLUDE_VKYC_CALL, SET_END_VKYC_CALL,
    SET_EXTRACT_DOCUMENT_DETAILS, SET_GENERATE_VKYC_REPORT, SET_INITIATE_VKYC_CALL,
    SET_MATCH_SCORE,
    SET_ONBOARDING_STAGES,
    SET_PERFORM_CLIENT_ACTION,
    SET_UPLOAD_SCREEN_RECORDING,
    SET_VKYC_CAMERA_ORIENTATION,
    SET_VKYC_FLOW_STEP,
    SET_VKYC_IMAGE_URL,
    SET_VKYC_QUESTION_ANSWER,
    SET_VKYC_STEP,
    SET_VKYC_TITLE,
    UPLOAD_SCREEN_RECORDING,
    SET_LOADER_STATE,
    SET_PARTICIPANT_AUDIO_STREAM,
    CLEAR_PARTICIPANT_AUDIO_STREAM,
    SET_AUDIO_MUTE_FLAG,
    SET_REJECTION_REASONS,
    SET_DEVICE_PERMISSIONS,
    SET_CURRENT_PAGE,
    FLOW_PROGRESS,
    SET_CURRENT_STAGE_POINTER,
    SET_LOCAL_FILE_STORED_STATUS,
    SET_PARTICIPANT_JOINING_STATUS,
    SET_FACEMATCH_IMAGE_STATUS,
    SET_MEETING_LOADER_STATE,
    GET_CONCLUDE_VKYC_CALL,
    SET_ERROR_VIEW,
    SET_HEALTH_CHECK,
    ERR_HEALTH_CHECK,
    SET_CALL_REMARKS,
} from './constants';

/**
 *
 *  user position is flow can be determined by stepNumber and subStepNumber
 *
 */

const VKYC_REDUCER_INITIAL_STATE = {
    loading: false,
    err: '',
    activeMeeting: null,
    step: 0,
    meetings: {},
    data: null,
    title: 'VKYC Flow',
    participantAudioStream: null,
    muteAudioFlag: false,
    rejectionReasons: [],
    availableCallCount: 0,
    devicePermissionStatus: {
        status: false,
        error: '',
    },
    flow: {
        progress: FLOW_PROGRESS.INITIATE_CALL,
    },
    localFileStoredStatus: false,
    errorView: null,
    healthCheckStatus: 'LOADING',
};

const initialState = fromJS(VKYC_REDUCER_INITIAL_STATE);

function vkycReducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter api changes
        case GET_INITIATE_VKYC_CALL:
        case GET_PERFORM_CLIENT_ACTION:
        case GET_ONBOARDING_STAGES:
        case GET_CAPTURE_SCREENSHOT:
        case GET_EXTRACT_DOCUMENT_DETAILS:
        case GET_MATCH_SCORE:
        case GET_END_VKYC_CALL:
        case GET_GENERATE_VKYC_REPORT:
        case GET_CONCLUDE_VKYC_CALL:
        case UPLOAD_SCREEN_RECORDING: {
            const { key: keyArg = 'data', screenKey, clearData } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values[keyArg] = null;
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn([keyArg], null);
        }
        // Setter api merge changes
        case SET_AVAILABLE_CALLS:
        case SET_INITIATE_VKYC_CALL:
        case SET_CAPTURE_SCREENSHOT:
        case SET_PERFORM_CLIENT_ACTION:
        case SET_ONBOARDING_STAGES:
        case SET_CONCLUDE_VKYC_CALL:
        case SET_VKYC_CAMERA_ORIENTATION:
        case SET_EXTRACT_DOCUMENT_DETAILS:
        case SET_MATCH_SCORE:
        case SET_END_VKYC_CALL:
        case SET_GENERATE_VKYC_REPORT:
        case SET_UPLOAD_SCREEN_RECORDING: {
            const { screenKey, key: keyArg, value } = action.data;
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }

            return state
                .setIn([keyArg], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        // Err api Changes
        case ERR_AVAILABLE_CALLS:
        case ERR_INITIATE_VKYC_CALL:
        case ERR_CONCLUDE_VKYC_CALL:
        case ERR_PERFORM_CLIENT_ACTION:
        case ERR_ONBOARDING_STAGES:
        case ERR_CAPTURE_SCREENSHOT:
        case ERR_EXTRACT_DOCUMENT_DETAILS:
        case ERR_MATCH_SCORE:
        case ERR_END_VKYC_CALL:
        case ERR_GENERATE_VKYC_REPORT:
        case ERR_UPLOAD_SCREEN_RECORDING:
        case ERR_HEALTH_CHECK: {
            const { screenKey, err } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['data'], null);
        }
        // Setter non api changes
        case SET_CALL_REMARKS:
        case SET_FACEMATCH_IMAGE_STATUS:
        case SET_ERROR_VIEW:
        case SET_HEALTH_CHECK: {
            const { data, key } = action.data;
            if (!Array.isArray(key)) {
                return state
                    .setIn([key], data);
            }
            return state
                .setIn(key, data);
        }
        case SET_VKYC_STEP: {
            const { step } = action.data;
            return state
                .setIn(['step'], step);
        }
        case SET_VKYC_FLOW_STEP: {
            const { meetingId, step } = action.data;
            return state
                .setIn(['meetings', meetingId, 'stepNumber'], step);
        }
        case SET_VKYC_QUESTION_ANSWER: {
            const { meetingId, questionType } = action.data;
            return state
                .setIn(['meetings', meetingId, 'answers', questionType], action.data);
        }
        case SET_VKYC_TITLE: {
            const { title } = action.data;
            return state
                .setIn(['title'], title);
        }
        case SET_VKYC_IMAGE_URL: {
            const { meetingId, imageKey, imageUrl } = action.data;
            return state
                .setIn(['meetings', meetingId, 'stages', [state?.step], imageKey], imageUrl);
        }
        case SET_LOADER_STATE: {
            return state
                .setIn(['loading'], action.data);
        }
        case SET_PARTICIPANT_AUDIO_STREAM:
            return state
                .setIn(['participantAudioStream'], action.data);
        case CLEAR_PARTICIPANT_AUDIO_STREAM:
            return state
                .setIn(['participantAudioStream'], null);
        case SET_AUDIO_MUTE_FLAG:
            return state
                .setIn(['muteAudioFlag'], action.data);
        case SET_REJECTION_REASONS:
            return state
                .setIn(['rejectionReasons'], action.data);
        case SET_DEVICE_PERMISSIONS: {
            const { status, error = null } = action.data;
            return state.setIn(['devicePermissionStatus'], {
                status,
                error,
            });
        }
        case SET_CURRENT_PAGE: {
            const { progress } = action.data;
            return state.setIn(['flow', 'progress'], progress);
        }
        case SET_CURRENT_STAGE_POINTER:
            return state
                .setIn(['meetings', action.data.meetingId, 'subStepNumber'], action.data.subStepNumber)
                .setIn(['meetings', action.data.meetingId, 'stepNumber'], action.data.stepNumber);
        case SET_LOCAL_FILE_STORED_STATUS:
            return state
                .setIn(['localFileStoredStatus'], action.data);

        case SET_PARTICIPANT_JOINING_STATUS:
            return state.setIn(['meetings', action.data.meetingId, 'participantJoiningStatus'], action.data.status);
        case SET_MEETING_LOADER_STATE:
            return state.setIn(['meetings', action.data.meetingId, 'loading'], action?.data?.loading);
        default:
            return state;
    }
}

export default vkycReducer;
