import React, {
    useState, useEffect, useRef, useCallback, useContext,
} from 'react';
import Cropper from 'react-easy-crop';

import DocumentContainerModal from '../containersV2/VKYCFlow/DocumentContainerModal/DocumentContainerModal';
import { MetaInfoContext, UserDetailsContext } from '../context';
import { Button, Carousel, LeftArrow, RightArrow } from '../components';
import { RudderEvent, pushRudderEvent } from '../rudderEvents';

import { imageNodeProxyRoute } from './downloadFile';

export const loadImage = (src) => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
});

const ImageCropUtils = (props, isNodeRouteRequired = true) => {
    const {
        url, styles, containerClass, handleImageReload, onReloadSuccess, onErrorAction,
    } = props;

    const metaInfo = useContext(MetaInfoContext);
    const {
        imageReloadConfig: reloadConfig,
    } = metaInfo;

    const { retryDelay = 1000, maxRetries: retryCount = 3 } = reloadConfig || {};

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [timeoutId, setTimeoutId] = useState(null);

    const retryCountRef = useRef(retryCount);

    const proxiedUrl = (isNodeRouteRequired && imageNodeProxyRoute(url)) || url;

    // Attempts to fetch an image and returns error details if unsuccessful
    const fetchImage = async (src) => {
        try {
            const response = await fetch(src);
            const {
                status, ok,
            } = response;
            if (!ok) {
                const errorText = await response.text();
                console.error('Image load error:', status, errorText);
                // Return formatted error message for non-200 responses
                if (onErrorAction) onErrorAction(errorText);
                return `${status}: ${errorText}`;
            }
            // Return false to indicate successful fetch
            return false;
        } catch (error) {
            console.error('Image fetch error:', error);
            if (onErrorAction) onErrorAction(error);
            return error;
        }
    };

    const retryImageLoad = async (currentTarget) => {
        // Decrement retry counter
        retryCountRef.current = retryCountRef && retryCountRef.current && retryCountRef.current - 1;
        const { src } = currentTarget || {};

        // Attempt to fetch the image
        const err = await fetchImage(src);

        // If fetch successful, clean up and reset
        if (!err) {
            setTimeoutId(null);
            retryCountRef.current = retryCount;
            onReloadSuccess(src);

            // Force image reload by changing the src
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = src;
            if (handleImageReload && typeof handleImageReload === 'function') handleImageReload({ isLoading: false });
            return;
        }

        // If we've exhausted retry attempts, clean up and notify of final failure
        if (retryCountRef && retryCountRef.current && retryCountRef.current < 2) {
            setTimeoutId(null);
            retryCountRef.current = retryCount;
            if (handleImageReload && typeof handleImageReload === 'function') {
                handleImageReload({
                    isLoading: false, isFinal: true, errText: err, proxiedUrl: src, url,
                });
            }
            return;
        }

        // If we still have retries left, set up the next retry interval
        const newTimeoutId = setTimeout(retryImageLoad, retryDelay, currentTarget);
        setTimeoutId(newTimeoutId);
    };

    // Handles image loading errors and implements retry logic
    const handleError = useCallback((e) => {
        const { currentTarget } = e || {};
        // Only start retry process if no retry is currently in progress
        if (!timeoutId) {
            // Notify parent component that reload attempt is starting
            if (handleImageReload && typeof handleImageReload === 'function') handleImageReload({ isLoading: true });

            // Start retry process
            const newTimeoutId = setTimeout(retryImageLoad, retryDelay, currentTarget);
            setTimeoutId(newTimeoutId);
        }
    }, []);

    return (
        <div className={containerClass}>
            <Cropper
                image={proxiedUrl}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                style={styles}
                maxZoom={5}
                mediaProps={{
                    onError: handleError,
                }}
            />
        </div>
    );
};

export const AutoScaleImageViewer = ({
    url,
    handleReload,
    captureOnClick,
    baseContainerClass = '',
    baseImageClass = '',
    landscapeContainerClass = '',
    landscapeImageClass = '',
    pdfContainerClass = '',
    arrayContainerClass = '',
    isArrayOfImages = false,
    carouselScrollValue = 0,
    meetingId = '',
    stageName = '',
    subStageType = '',
    onErrorAction,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPortraitImage, setAsPortrait] = useState(true);
    const [isPDF, setIsPDF] = useState(false);
    const iframeRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [showZoomInModal, setShowZoomInModal] = useState(false);
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    if (url && Array.isArray(url) && url.length === 1) {
        // eslint-disable-next-line no-param-reassign
        url = url[0].presigned_read_url;
    }

    const imageUrlSrc = (url && !Array.isArray(url) && imageNodeProxyRoute(url)) || url;

    const handleWheel = (event) => {
        event.preventDefault();
        const delta = event.deltaY < 0 ? 0.1 : -0.1; // Zoom in or out
        setScale((prevScale) => Math.max(0.5, Math.min(prevScale + delta, 3))); // Limit zoom level
    };

    const loadImageDimensionType = (src) => {
        loadImage(src).then((img) => {
            const isHorizontal = img.width > img.height;
            setAsPortrait(!isHorizontal);
            setIsLoading(false);
        }).catch((err) => {
            console.error('Error loading image:', err);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (url && !Array.isArray(url)) {
            // Check if the URL ends with .pdf
            if (imageUrlSrc) {
                const urlObject = new URL(imageUrlSrc);
                const pathname = urlObject.pathname && urlObject.pathname.toLowerCase();
                if (pathname && pathname.endsWith('.pdf')) {
                    setIsPDF(true);
                    return;
                }
                setIsPDF(false);
            }

            setIsPDF(false);
            setIsLoading(true);
            loadImageDimensionType(imageUrlSrc);
        }
    }, [imageUrlSrc, url]);

    useEffect(() => {
        const iframe = iframeRef.current;

        if (iframe) {
            iframe.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    if (url && Array.isArray(url)) {
        return (
            <div className={arrayContainerClass}>
                <Carousel
                    leftArrow={<LeftArrow />}
                    rightArrow={<RightArrow />}
                    leftScrollValue={carouselScrollValue}
                    rightScrollValue={carouselScrollValue}
                >
                    <div style={{
                        display: 'flex',
                        gap: '20px',
                        height: '100%',
                    }}
                    >
                        {
                            url.map(({ presigned_read_url: imageUrl, image_identifier: imageIdentifier }, index) => (
                                <AutoScaleImageViewer
                                    key={imageIdentifier || index}
                                    url={imageUrl}
                                    handleReload={handleReload}
                                    baseContainerClass={baseContainerClass}
                                    baseImageClass={baseImageClass}
                                    landscapeContainerClass={landscapeContainerClass}
                                    landscapeImageClass={landscapeImageClass}
                                    pdfContainerClass={pdfContainerClass}
                                    isArrayOfImages
                                    onErrorAction={onErrorAction}
                                />
                            ))
                        }
                    </div>
                </Carousel>
            </div>
        );
    }

    if (isPDF) {
        return (
            <div className={`${baseContainerClass} ${landscapeContainerClass}`}>
                <div className={`${pdfContainerClass}`} onWheel={handleWheel}>
                    <iframe
                        ref={iframeRef}
                        src={`${imageUrlSrc}`}
                        width='100%'
                        height='100%'
                        title='PDF Viewer'
                        className={`${pdfContainerClass}-iframe`}
                        style={{
                            transform: `scale(${scale})`,
                        }}
                        type='application/pdf'
                    />
                </div>
                <Button
                    v2
                    secondary
                    label='Zoom'
                    onClick={() => {
                        setShowZoomInModal(true);
                        pushRudderEvent(RudderEvent.VKYC.ZoomInImageClicked, agentInfo.emailId, {
                            meetingId,
                            stageName,
                            subStageType,
                        });
                    }}
                    extClasses='vkyc-image-btn__capture vkyc-image-btn__capture-horizontal'
                />
                <DocumentContainerModal visible={showZoomInModal} handleCancel={() => setShowZoomInModal(false)} contentSrc={imageUrlSrc} />
            </div>
        );
    }

    return (
        <div className={` ${baseContainerClass} 
        ${!isPortraitImage && landscapeContainerClass}`}
        >
            {
                !isLoading ? (
                    <ImageCropUtils
                        url={url}
                        containerClass={
                            `${baseImageClass} ${!isPortraitImage && landscapeImageClass}`
                        }
                        handleImageReload={handleReload}
                        onReloadSuccess={(imageSrc) => loadImageDimensionType(imageSrc)}
                        onErrorAction={onErrorAction}
                    />
                ) : ''
            }
            { captureOnClick && !isArrayOfImages && (
                <Button
                    v2
                    secondary
                    label={imageUrlSrc ? 'Retake' : 'Capture'}
                    extClasses={`vkyc-image-btn__capture ${!isPortraitImage && 'vkyc-image-btn__capture-horizontal'}`}
                    onClick={() => {
                        captureOnClick();
                        if (imageUrlSrc) {
                            pushRudderEvent(RudderEvent.VKYC.RetakeCTAClicked, agentInfo.emailId, {
                                meetingId,
                                stageName,
                                subStageType,
                            });
                        } else {
                            pushRudderEvent(RudderEvent.VKYC.CaptureCTAClicked, agentInfo.emailId, {
                                meetingId,
                                stageName,
                                subStageType,
                            });
                        }
                    }}
                />
            )}

            {/* to show zoom-in button if captureOnClick function is not provided */}
            { !captureOnClick && (
                <Button
                    v2
                    secondary
                    label='Zoom'
                    onClick={() => setShowZoomInModal(true)}
                    extClasses={`vkyc-image-btn__capture ${!isPortraitImage && 'vkyc-image-btn__capture-horizontal'}`}
                />
            )}
            <DocumentContainerModal visible={showZoomInModal} handleCancel={() => setShowZoomInModal(false)} contentSrc={imageUrlSrc} />
        </div>
    );
};

export default ImageCropUtils;
