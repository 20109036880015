/**
 * @file VKYC contains rudder event names for VKYC Agent level
 */

const VKYCAgentViewRecordingStarted = 'VKYCAgentViewRecordingStarted';
const VKYCAgentViewRecordingOngoing = 'VKYCAgentViewRecordingOngoing';
const VKYCAgentViewRecordingStopped = 'VKYCAgentViewRecordingStopped';
const VKYCAgentApprovalRemarksLoaded = 'VKYCAgentApprovalRemarksLoaded';
const VKYCDocumentVerificationLoaded = 'VKYCDocumentVerificationLoaded';
const VKYCEmiratesIDCaptureLoaded = 'VKYCEmiratesIDCaptureLoaded';
const VKYCFaceCaptureLoaded = 'VKYCFaceCaptureLoaded';
const VKYCImageVerificationStepLoaded = 'VKYCImageVerificationStepLoaded';
const VKYCLocationCheckLoaded = 'VKYCLocationCheckLoaded';
const VKYCPanCardStepLoaded = 'VKYCPanCardStepLoaded';
const VKYCPassportCaptureLoaded = 'VKYCPassportCaptureLoaded';
const VKYCQuestionStepLoaded = 'VKYCQuestionStepLoaded';
const VKYCReportDetailsLoaded = 'VKYCReportDetailsLoaded';
const CancelCTAClicked = 'CancelCTAClicked';
const ApproveCTAClicked = 'ApproveCTAClicked';
const InitiateCallCTAClicked = 'InitiateCallCTAClicked';
const RetryCTAClicked = 'RetryCTAClicked';
const YesCTAClicked = 'YesCTAClicked';
const NoCTAClicked = 'NoCTAClicked';
const RetakeCTAClicked = 'RetakeCTAClicked';
const ContinueCTAClicked = 'ContinueCTAClicked';
const SwitchCameraSideCTAClicked = 'SwitchCameraSideCTAClicked';
const CaptureCTAClicked = 'CaptureCTAClicked';
const EndCallCTAClicked = 'EndCallCTAClicked';
const ConfirmCTAClicked = 'ConfirmCTAClicked';
const RejectCTAClicked = 'RejectCTAClicked';
const DocumentZoomInLoaded = 'DocumentZoomInLoaded';
const AuditorScreenLoaded = 'AuditorScreenLoaded';
const ZoomInImageClicked = 'ZoomInImageClicked';
const AuditorApproveCtaClicked = 'AuditorApproveCtaClicked';
const AuditorRejectCtaClicked = 'AuditorRejectCtaClicked';
const AddRemarkClicked = 'AddRemarkClicked';

export {
    VKYCAgentViewRecordingStarted,
    VKYCAgentViewRecordingOngoing,
    VKYCAgentViewRecordingStopped,
    VKYCAgentApprovalRemarksLoaded,
    VKYCDocumentVerificationLoaded,
    VKYCEmiratesIDCaptureLoaded,
    VKYCFaceCaptureLoaded,
    VKYCImageVerificationStepLoaded,
    VKYCLocationCheckLoaded,
    VKYCPanCardStepLoaded,
    VKYCPassportCaptureLoaded,
    VKYCQuestionStepLoaded,
    VKYCReportDetailsLoaded,
    CancelCTAClicked,
    ApproveCTAClicked,
    InitiateCallCTAClicked,
    RetryCTAClicked,
    YesCTAClicked,
    NoCTAClicked,
    RetakeCTAClicked,
    ContinueCTAClicked,
    SwitchCameraSideCTAClicked,
    CaptureCTAClicked,
    EndCallCTAClicked,
    ConfirmCTAClicked,
    RejectCTAClicked,
    DocumentZoomInLoaded,
    AuditorScreenLoaded,
    ZoomInImageClicked,
    AuditorApproveCtaClicked,
    AuditorRejectCtaClicked,
    AddRemarkClicked,
};
