import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import CorrectWrongIcon from '../../../../components/CorrectWrongIcon';
import { UserDetailsContext } from '../../../../context';
import { RudderEvent, pushRudderEvent } from '../../../../rudderEvents';
import { logStageProgress } from '../../actions';

const LocationStep = ({ data, meetingId }) => {
    const dispatch = useDispatch();
    const locationDetails = data?.stages?.[0]?.location?.location_details || [];
    const coordinates = data?.stages?.[0]?.location?.location?.coordinates || {};
    const recordedAt = data?.stages?.[0]?.location?.location?.recorded_at;
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const logUserEvent = (eventName, currentData) => {
        dispatch(logStageProgress({
            message: eventName,
            currentData,
        }));
    };

    const maskSensitiveData = () => {
        const logCoordinates = data?.stages?.[0]?.location?.location?.coordinates || {};
        const logLocationDetails = locationDetails.map((details) => ({
            ...details,
            expected_answer: '***',
        }));
        logCoordinates.latitude = '***';
        logCoordinates.longitude = '***';
        const combinedData = [
            logLocationDetails,
            logCoordinates,
        ];

        return combinedData;
    };

    useEffect(() => {
        logUserEvent('Location step mounted', maskSensitiveData());
        pushRudderEvent(RudderEvent.VKYC.VKYCLocationCheckLoaded, agentInfo.emailId, {
            meetingId,
            stageName: data?.stage_name,
        });
    }, []);

    if (!data?.stages?.[0]?.location) {
        logUserEvent('Location stage data invalid', maskSensitiveData());
    }

    return (
        <div className='vkyc-location-container'>
            <div>
                {locationDetails.map((locationKVPair) => (
                    <div className='mb-30 frcsbWrapper'>
                        <div>
                            <div className='sop-step-no'>{locationKVPair?.label || 'N/A'}</div>
                            <div className='sop-step-title'>{locationKVPair?.expected_answer || 'N/A'}</div>
                        </div>
                        <div>
                            <CorrectWrongIcon isCorrect={locationKVPair?.is_valid ?? false} />
                        </div>
                    </div>
                ))}
            </div>
            <div className='vkyc-location-lat-long_container'>
                <div className='vkyc-location-lat-long_kv-container'>
                    <div className='vkyc-location-lat-long_key'>Latitude</div>
                    <div className='vkyc-location-lat-long_value'>
                        {coordinates?.latitude || 'N/A'}
                    </div>
                </div>
                <div>
                    <div className='vkyc-location-lat-long_key'>Longitude</div>
                    <div className='vkyc-location-lat-long_value'>
                        {coordinates?.longitude || 'N/A'}
                    </div>
                </div>
                <div>
                    <div className='vkyc-location-lat-long_key'>Capture Timestamp</div>
                    <div className='vkyc-location-lat-long_value'>{
                        recordedAt?.seconds
                        && recordedAt?.seconds !== 0
                            ? (new Date()).toLocaleString()
                            : recordedAt?.seconds
                    }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationStep;
