/**
 * @file Manages the saga generator functions for Wealth P2P component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    PAL_USER_INFO_ENDPOINT, PAL_LOANS_INFO_ENDPOINT, PAL_LOANS_FORECLOSURE_AMOUNT_ENDPOINT, PAL_LOANS_ADDITIONAL_INFO_ENDPOINT,
} from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_USER_INFO, SET_USER_INFO, ERR_USER_INFO,
    GET_LOANS_INFO, SET_LOANS_INFO, ERR_LOANS_INFO, GET_LAMF_USER_INFO, GET_LAMF_LOANS_INFO,
    SET_LAMF_USER_INFO, ERR_LAMF_USER_INFO, SET_LAMF_LOANS_INFO, ERR_LAMF_LOANS_INFO, SET_FORECLOSURE_AMOUNT,
    ERR_FORECLOSURE_AMOUNT, GET_FORECLOSURE_AMOUNT, GET_LAMF_LOANS_ADDITIONAL_INFO, SET_LAMF_LOANS_ADDITIONAL_INFO,
    ERR_LAMF_LOANS_ADDITIONAL_INFO, ERR_LOANS_ADDITIONAL_INFO, SET_LOANS_ADDITIONAL_INFO, GET_LOANS_ADDITIONAL_INFO,
} from './constants';

function* getUserInfo(action) {
    const {
        ticketId, userId, index,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_USER_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_INFO, data: { ticketId, err } });
    }
}

function* getLoansList(action) {
    const {
        ticketId, userId, index,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_LOANS_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_LOANS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOANS_INFO, data: { ticketId, err } });
    }
}

function* getForeclosureAmount(action) {
    const {
        ticketId, userId, index, loanAccountNumber, loanDetails, idx,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, loanAccountNumber, loanDetails,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_FORECLOSURE_AMOUNT_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_FORECLOSURE_AMOUNT, data: { ticketId, ...response, idx } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_FORECLOSURE_AMOUNT, data: { ticketId, idx } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FORECLOSURE_AMOUNT, data: { ticketId, err, idx } });
    }
}

function* getLAMFUserInfo(action) {
    const {
        ticketId, userId, index, requestType,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, requestType });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_USER_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_LAMF_USER_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_LAMF_USER_INFO, data: { ticketId } });
        }

        /* TODO: Need to remove this code as it is not required after checking its use-case(https://monorail.pointz.in/p/fi-app/issues/detail?id=83977) */
        // const { userInfo: { loanApplicationStatus } } = response;
        // if (loanApplicationStatus === 'Accepted') {
        //     yield call(getLoansList, { data: action.data });
        // }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LAMF_USER_INFO, data: { ticketId, err } });
    }
}

function* getLAMFLoansList(action) {
    const {
        ticketId, userId, index, requestType,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, requestType });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_LAMF_LOANS_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_LAMF_LOANS_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LAMF_LOANS_INFO, data: { ticketId, err } });
    }
}

function* getLAMFLoansAdditionalDetails(action) {
    const {
        ticketId, userId, index, meta, resolve,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, meta });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_ADDITIONAL_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_LAMF_LOANS_ADDITIONAL_INFO, data: { ticketId, ...response } });
            if (resolve) {
                yield call(resolve);
            }
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_LAMF_LOANS_ADDITIONAL_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LAMF_LOANS_ADDITIONAL_INFO, data: { ticketId, err } });
    }
}

function* getLoansAdditionalDetails(action) {
    const {
        ticketId, userId, index, meta, resolve,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, meta });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAL_LOANS_ADDITIONAL_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_LOANS_ADDITIONAL_INFO, data: { ticketId, ...response } });
            if (resolve) {
                yield call(resolve);
            }
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_LOANS_ADDITIONAL_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOANS_ADDITIONAL_INFO, data: { ticketId, err } });
    }
}

export default function* preApprovedLoansSaga() {
    yield all(
        [
            yield takeLatest(GET_USER_INFO, getUserInfo),
            yield takeLatest(GET_LOANS_INFO, getLoansList),
            yield takeLatest(GET_FORECLOSURE_AMOUNT, getForeclosureAmount),
            yield takeLatest(GET_LAMF_USER_INFO, getLAMFUserInfo),
            yield takeLatest(GET_LAMF_LOANS_INFO, getLAMFLoansList),
            yield takeLatest(GET_LAMF_LOANS_ADDITIONAL_INFO, getLAMFLoansAdditionalDetails),
            yield takeLatest(GET_LOANS_ADDITIONAL_INFO, getLoansAdditionalDetails),
        ],
    );
}
