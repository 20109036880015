/**
 * @file react data grid utils
 */

import { CREDIT, DEBIT } from '../constants';

/**
 * @example
 *  object exmaple
 * data = {foo:{cat:{sound:'meow'}}}
 * key  = foo.cat.sound
 * getObjectValue(data,key) // 'meow'
 *
 * @example
 * array example
 * data = {foo:[{cat:{sound:'meow'}}]}
 * key  = foo.0.cat.sound
 * getObjectValue(data,key) // 'meow'
 *
 * @param {object | array} data - array or object
 * @param {string} key - path to get the value
 */
export const getObjectValue = (data, key = '') => {
    const keyArr = key.split('.');
    let value = data;

    for (let i = 0; i < keyArr.length; i += 1) {
        if (keyArr[i] in value) {
            value = value[keyArr[i]];
        } else {
            return undefined;
        }
    }

    return value;
};

/**
 *  Compare two value and return -1, 0, 1
 * @param {string|number} a
 * @param {string|number} b
 * @returns {0|1|-1}
 */
export function compare(a, b) {
    // convert non numeric values to lowercase string
    const aStr = typeof a !== 'number' ? (a ?? '').toString().toLowerCase() : a;
    const bStr = typeof b !== 'number' ? (b ?? '').toString().toLowerCase() : b;

    if (aStr > bStr) return 1;

    if (aStr < bStr) return -1;

    return 0;
}

/**
 * Multilevel sort
 * @param {Object[]} objects - array to be sorted
 * @param {({columnKey:string,direction:'ASC'|'DESC'} | string)[]} keys - array of column keys and sort directions or array of column keys
 * @param {'ASC'|'DESC'} defaultDirection -default sort direction
 * @returns {Object[]} sorted array
 */
export function multiLevelSort(objects, keys, defaultDirection = 'ASC') {
    if (!objects || !objects.length) {
        return objects;
    }
    //
    const isObjectArrayType = typeof objects[0] === 'object';

    // return the same object when columns keys not found if isObjectArrayType is true
    if ((!keys || !keys.length) && isObjectArrayType) return objects;

    // sorted array using Array sort method
    return objects.sort((a, b) => {
        let res = 0; // default to equal

        if (isObjectArrayType) { // enters if it's an array of object
            for (let i = 0; i < keys.length; i += 1) {
                if (res !== 0) return res;

                const value = keys[i];
                const key = value?.columnKey || value; // setting key
                const direction = (value?.direction || defaultDirection) === 'ASC' ? 1 : -1; // setting direction in number
                const aVal = getObjectValue(a, key);
                const bVal = getObjectValue(b, key);

                if (aVal == null) return 1; // Move items with null firstName to the end

                if (bVal == null) return -1; // Move items with null firstName to the end

                res = compare(aVal, bVal) * direction;
            }
        } else { // enters if it's a none object array example string or number array
            const direction = defaultDirection === 'ASC' ? 1 : -1;

            if (a == null) return 1; // Move items with null firstName to the end

            if (b == null) return -1; // Move items with null firstName to the end

            res = compare(a, b) * direction;
        }
        return res;
    });
}

/**
 * Separate the compare operators from the value
 * @param {string} value
 * @returns {{searchString:string}|{searchString:string,symbol:string}}
 */
export function extractAndRemoveSymbol(value = '') {
    const regex = /^(>=|<=|<|>|=)/;
    const match = value.match(regex);

    if (match) {
        const symbol = match[0];
        const result = value.replace(new RegExp(`^${symbol}`), '');

        return { searchString: result, symbol };
    }

    return { searchString: value };
}

/**
 * compare num1 and num2 based on symbols (compare operators)
 * @param {*} num1
 * @param {*} num2
 * @param {*} symbol
 * @returns {boolean}
 */
export function performOperation(num1, num2, symbol) {
    switch (symbol) {
        case '<':
            return num1 < num2;
        case '>':
            return num1 > num2;
        case '<=':
            return num1 <= num2;
        case '>=':
            return num1 >= num2;
        case '=':
            return num1 === num2;
        default:
            return false;
    }
}

/**
 * check for type as 'date' and value as number
 * @param {*} type
 * @param {*} value
 * @returns {boolean}
 */
const checkDateTypeAndValue = (type, value) => type === 'date' && typeof +value === 'number';

/**
 * multilevel filter
 * @param {Object[]} data
 * @param {*} filter - filter object, column key and filter value pairs
 * @returns {Object[]}
 */
export const multiLevelFilter = (data, filter = {}) => {
    // filtering valid string values
    const values = Object.values(filter).filter((item) => !!item);

    if (values.length) {
        const keys = Object.keys(filter); // get filter keys

        return data.filter((item) => {
            let flag = true;

            // looping all the filter key to check for atmost matches
            for (let i = 0; i <= keys.length; i += 1) {
                const key = keys[i]; // filter key
                const filterData = filter[key]; // filter string
                const sString = (filterData?.value || filterData || '').trim().toLowerCase(); // covert filter string to lower case
                const { searchString, symbol } = extractAndRemoveSymbol(sString); // extracting symbols and filter string if any
                const value = (item[key] || '').toString().toLowerCase(); // convert value to lower case

                if (checkDateTypeAndValue(filterData?.type, value)) { // enters if it's a date type
                    const d = new Date(+value);

                    if (!d.toLocaleString().includes(searchString)) {
                        flag = false;
                    }
                } else if (symbol && searchString) { // enters if it contain symbols and filter string
                    flag = performOperation(parseFloat(value), parseFloat(searchString), symbol);
                } else if (!value.includes(searchString)) {
                    flag = false;
                }

                if (flag === false) break;
            }

            return flag;
        });
    }

    return [...data];
};

/**
 * Get Credit (+) / Debit (-) symbols
 * @param {*} type
 * @returns {string}
 */
export const getCreditDebitSymbol = (type) => {
    if (type === CREDIT) {
        return '+';
    }
    if (type === DEBIT) {
        return '-';
    }
    return '';
};

/**
 * Persist data in local storage
 * @param {string} key
 * @param {object | array} data
 * @returns {void}
 */
export const persistData = (key, data) => localStorage.setItem(key, JSON.stringify(data));

/**
 * Get persisted data from local storage
 * @param {string} key
 * @param {object | array} defaultvalue
 * @returns {object | array}
 */
export const getPersistedData = (key, defaultvalue = {}) => {
    try {
        return JSON.parse(localStorage.getItem(key)) || defaultvalue;
    } catch {
        return defaultvalue;
    }
};

/**
 * Clear persisted data from local storage
 * @param {*} key
 */
export const clearPersistedData = (key) => {
    localStorage.removeItem(key);
};

/**
 * Get Formatted Date for Transaction V2 Table
 * @param {string | number | Date} value
 * @param {'date'| 'month' | 'year' | undefined } flag
 * @returns {string} Formatted Date
 */
export const getDateforTxnTable = (value, flag) => {
    const date = new Date(value);
    const locale = 'en-GB';

    if (flag) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

        if (flag === 'month' || flag === 'year') { // removing day for month and year
            delete options.day;
        }

        if (flag === 'year') { // removing month for year
            delete options.month;
        }

        // eg: (flag = 'date') => 27/06/2023
        // eg: (flag = 'month') => 06/2023
        // eg: (flag = 'year') => 2023
        return date.toLocaleString(locale, options);
    }

    return date.toLocaleString(locale); // eg: => 27/06/2023, 11:40:56
};

/**
 * Determines whether a new block should be started based on the given conditions.
 *
 * @param {Array} currentBlock - The current block of transactions.
 * @param {number} balance - The current balance.
 * @param {number} newAmount - The amount of the new transaction.
 * @param {number} timeDiff - The time difference between the current and the new transaction in milliseconds.
 * @returns {boolean} - Returns true if a new block should be started, otherwise false.
 */
const shouldStartNewBlock = (currentBlock, balance, newAmount, timeDiff) => {
    // If the current block is empty, don't start a new one.
    if (currentBlock.length === 0) return false;

    // Start a new block if:
    // 1. The absolute balance is less than 1000.
    // 2. The time difference between transactions is greater than 24 hours.
    // 3. The absolute value of the new amount is more than twice the absolute value of the current balance.
    return Math.abs(balance) < 1000
        || timeDiff > 24 * 60 * 60 * 1000
        || Math.abs(newAmount) > Math.abs(balance) * 2;
};

/**
 * Finalizes a block of transactions by updating the fifoBlocksMap with transaction details.
 *
 * @param {Object} fifoBlocksMap - The map containing existing transaction blocks.
 * @param {Array} block - An array of transaction IDs to be finalized.
 * @param {string} startDate - The start date of the transaction block.
 * @param {string} endDate - The end date of the transaction block.
 * @param {number} balance - The balance associated with the transaction block.
 * @param {string} color - The color associated with the transaction block.
 * @returns {Object} - The updated map with finalized transaction blocks.
 */
const finalizeBlock = (fifoBlocksMap, block, startDate, endDate, balance, color, percentage) => {
    const formattedEndDate = new Date(endDate).toISOString();
    const updatedMap = { ...fifoBlocksMap };
    block.forEach((txnId) => {
        updatedMap[txnId] = {
            start_date: startDate,
            end_date: formattedEndDate,
            transactions: [...block],
            balance,
            color,
            transaction_count: block.length,
            percentage,
        };
    });
    return updatedMap;
};

/**
 * Generates a color in HSL format based on the balance and the number of transactions.
 *
 * @param {number} balance - The balance value used to determine the hue.
 * @param {Array} transactions1 - An array of transactions used to determine the saturation.
 * @returns {string} The generated color in HSL format.
 */
const getBlockColor = (percentage) => {
    // Define hue range: Red (0) to Orange (~30) to Yellow (60)
    let hue;
    if (percentage <= 7.5) {
        // Transition from Red (0) to Orange (30)
        hue = (percentage / 7.5) * 30;
    } else {
        // Transition from Orange (30) to Yellow (60)
        hue = 30 + ((percentage - 7.5) / 7.5) * 30;
    }

    return `hsl(${hue}, 90%, 60%)`; // Keeping saturation 90% and lightness 60% for vibrancy
};

/**
 * Calculates the percentage of the current balance relative to the total credit.
 * If the total credit is zero or equal to the absolute value of the current balance,
 * the percentage is set to 100. Additionally, if any transaction in the current block
 * belongs to a merchant listed in the provided merchant names, the percentage is set to 100.
 *
 * @param {number} currentBalance - The current balance.
 * @param {number} totalCredit - The total credit.
 * @param {Array<number>} currentBlock - An array of transaction IDs in the current block.
 * @param {Set<string>} merchantNames - A set of merchant names from the CSV.
 * @param {Array<Object>} transactions - An array of transaction objects.
 * @param {number} transactions[].transaction_id - The ID of the transaction.
 * @param {string} transactions[].merchant_name - The name of the merchant for the transaction.
 * @returns {number} The calculated percentage.
 */
const calculatePercentage = (currentBalance, totalCredit, currentBlock, merchantNames, transactions) => {
    let percentage = Math.round((Math.abs(currentBalance) / totalCredit) * 1000) / 10;
    if (!totalCredit || totalCredit === 0 || totalCredit === Math.abs(currentBalance)) {
        percentage = 100;
    }

    // Check if any transaction in the block belongs to a merchant in the CSV
    const isMerchantTransaction = currentBlock?.some((transactionId) => {
        const transaction = transactions?.find((txn) => txn.transaction_id === transactionId);
        return transaction && merchantNames?.has(transaction.merchant_name);
    });

    if (isMerchantTransaction) {
        percentage = 100;
    }
    return percentage;
};

/**
 * Calculates FIFO (First In, First Out) blocks for transactions in the browser.
 *
 * @param {Array} transactions - Array of transaction objects.
 * @param {string} transactions[].transaction_id - Unique identifier for the transaction.
 * @param {string} transactions[].credited_debited_at - Date when the transaction was credited or debited.
 * @param {string} transactions[].transaction_status - Status of the transaction (e.g., 'Success').
 * @param {string} transactions[].accounting_entry_type - Type of accounting entry ('CREDIT' or 'DEBIT').
 * @param {number} transactions[].amount - Amount of the transaction.
 * @returns {Object} - An object mapping transaction IDs to their respective FIFO block details.
 * @returns {Object} returnValue.transactionId - Details of the FIFO block for the given transaction ID.
 * @returns {string} returnValue.transactionId.start_date - Start date of the FIFO block.
 * @returns {string} returnValue.transactionId.end_date - End date of the FIFO block.
 * @returns {Array} returnValue.transactionId.transactions - Array of transaction IDs in the FIFO block.
 * @returns {number} returnValue.transactionId.balance - Balance of the FIFO block.
 * @returns {number} returnValue.transactionId.transaction_count - Number of transactions in the FIFO block.
 */
export const calculateFifoInBrowser = (transactions, merchantNames) => {
    // Return empty object if transactions array is null or empty.
    if (!transactions?.length) return {};

    // Sort transactions by credited_debited_at date.
    const sortedTransactions = [...transactions].sort(
        (a, b) => a.credited_debited_at - b.credited_debited_at,
    );

    let fifoBlocksMap = {};
    let currentBalance = 0;
    let currentBlock = [];
    let blockStartDate = null;
    let lastTxnDate = null;
    let totalCredit = 0;

    sortedTransactions.forEach((txn, index) => {
        if (txn.transaction_status === 'Success') {
            const txnDate = new Date(txn.credited_debited_at);
            const amount = txn.accounting_entry_type === 'CREDIT'
                ? txn.amount
                : -txn.amount;

            const timeDiff = lastTxnDate ? txnDate - lastTxnDate : 0;

            // Check if a new block should be started.
            if (shouldStartNewBlock(currentBlock, currentBalance, amount, timeDiff)) {
                // Finalize the current block if it exists.
                if (currentBlock.length > 0) {
                    const percentage = calculatePercentage(currentBalance, totalCredit, currentBlock, merchantNames, transactions);

                    fifoBlocksMap = finalizeBlock(
                        fifoBlocksMap,
                        currentBlock,
                        blockStartDate,
                        lastTxnDate,
                        currentBalance,
                        getBlockColor(percentage),
                        percentage,
                    );
                }
                // Start a new block.
                currentBlock = [];
                blockStartDate = txn.credited_debited_at;
                currentBalance = 0;
                totalCredit = 0;
            }

            // If it's the very first transaction set block start date.
            if (blockStartDate === null) {
                blockStartDate = txn.credited_debited_at;
            }

            // Update balance and current block.
            currentBalance += amount;
            currentBlock.push(txn.transaction_id);
            lastTxnDate = txnDate;
            if (txn.accounting_entry_type === 'CREDIT') {
                totalCredit += txn.amount; // Accumulate credit amounts
            }

            // Finalize the last block if it's the last transaction.
            if (index === sortedTransactions.length - 1 && currentBlock.length > 0) {
                const percentage = calculatePercentage(currentBalance, totalCredit, currentBlock, merchantNames, transactions);

                fifoBlocksMap = finalizeBlock(
                    fifoBlocksMap,
                    currentBlock,
                    blockStartDate,
                    txn.credited_debited_at,
                    currentBalance,
                    getBlockColor(percentage),
                    percentage,
                );
            }
        }
    });

    return fifoBlocksMap;
};
