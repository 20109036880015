import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../components';

import VideoStreamLayout from '../VideoStreamLayout';
import { selectMeetingLoadingValue, selectMeetingId, selectHealthCheck } from '../selectors';
import { getOnboardingStages, logStageProgress } from '../actions';

import CurrentStage from './CurrentStage';
import BottomOptions from './BottomOptions';

const Fallback = ({ error }) => (
    <div>
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
);

const CallScreen = (props) => {
    const dispatch = useDispatch();
    const { stopRecording, omegleCallId } = props;
    const [nextStepEnable, setNextStepEnable] = useState(false);
    const meetingId = useSelector(selectMeetingId, shallowEqual);
    const meetingLoading = useSelector(selectMeetingLoadingValue(meetingId));
    const healthCheck = useSelector(selectHealthCheck, shallowEqual);
    const logErrorToService = (error, info) => {
        dispatch(logStageProgress({
            message: 'Component loading failed',
            info,
            error,
        }));
    };
    useEffect(() => {
        if (healthCheck === 'PASSED') {
            dispatch(getOnboardingStages({ meetingId, screenKey: ['meetings', meetingId] }));
        }
    }, [healthCheck]);

    return (
        <div>
            <div className='vkyc-meet-id'>
                { omegleCallId && (
                    <div>Call Id: {omegleCallId},&nbsp;</div>
                )}
                { meetingId && (
                    <div>Meeting Id: {meetingId}</div>
                )}
            </div>
            <div className='vkyc-container'>
                <div className='vkyc-left-video-container'>

                    <VideoStreamLayout />
                </div>
                <div className='vkyc-right-qna-container'>
                    <div className='vkyc-qna-container'>
                        <ErrorBoundary
                            FallbackComponent={Fallback}
                            onError={logErrorToService}
                        >
                            <CurrentStage stopRecording={stopRecording} setNextStepEnable={setNextStepEnable} />
                        </ErrorBoundary>
                    </div>
                    <div className='vkyc-qna-line' />
                    <BottomOptions nextStepEnable={nextStepEnable} setNextStepEnable={setNextStepEnable} stopRecording={stopRecording} />
                </div>
                <Loader visible={!!meetingLoading} loadingText={typeof meetingLoading === 'string' ? meetingLoading : ''} />
            </div>
        </div>
    );
};

export default CallScreen;
