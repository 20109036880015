/**
 * @file Tiering Plans main container
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { compose } from 'redux';

import { useHistory } from 'react-router-dom';
import {
    KVPairInfo, Loader, Table, Button, Modal, SingleDatePicker, Toggle, FormWithSingleInput,
} from '../../components';
import { injectReducer, injectSaga, isNilOrEmpty, toastify } from '../../utils';
import { hasAccessToThePage } from '../../utils/helpers';
import { SCREEN } from '../Tickets/constants';

import {
    TIERING_KEY, TIERING_TABLE_LABEL_DATA, ELIGIBILITY_TIER_MOVEMENT_DATA,
    EXTEND_TO_DATE_INFO, INITIAL_DATE_RANGE,
    DATE_RANGE,
    customTieringModalStyles,
} from './constants';
import {
    getTieringDetails,
    // getTieringSensitiveDetails,
    setTieringGrace,
    setTieringCoolOff,
    getUserAbuseHistory,
} from './actions';
import { getTieringList, getPlanName, getAbuseHistory } from './utils';
import { selectTieringinfo, selectUserAbuseHistory } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';
import { setAuthUpgradeInfo } from '../Tickets/actions';
import { DEFAULT_TICKET_PATH } from '../App/routes';

const Tiering = (props) => {
    const {
        activeTicket, ticketId, userId, index,
    } = props;

    const [dateSelected, setDateSelected] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const userTieringInfo = useSelector(selectTieringinfo, shallowEqual);
    const userAbuseHistory = useSelector(selectUserAbuseHistory, shallowEqual);

    const [showOverrideCoolOffModal, setOverrideCoolOffModal] = useState(false);
    const [showOverrideGraceModal, setOverrideGraceModal] = useState(false);
    const [extendToDate, setExtendToDate] = useState(DATE_RANGE);
    const [showTieringMovements, setShowTieringMovements] = useState(false);

    useEffect(() => {
        dispatch(getTieringDetails({ ticketId, userId, index }));
    }, []);

    useEffect(() => {
        if (dateSelected) {
            const date = new Date(dateSelected);
            dispatch(getUserAbuseHistory({ ticketId, dateSelected: date.toDateString() }));
        }
    }, [dateSelected]);

    let loadingUserInfo;
    let userData;
    let errInLoadingUserInfo;
    let loadingAbuseHistoryData;
    let userAbuseHistoryData;
    let errInLoadingUserAbuseHistoryData;

    const dateInfo = {
        toDateInfo: EXTEND_TO_DATE_INFO,
        initialDateRange: INITIAL_DATE_RANGE,
    };

    if (userAbuseHistory[ticketId]) {
        ({
            loading: loadingAbuseHistoryData, data: userAbuseHistoryData, err: errInLoadingUserAbuseHistoryData,
        } = userAbuseHistory[ticketId]);
    }

    if (userTieringInfo[ticketId]) {
        ({
            loading: loadingUserInfo, data: userData, err: errInLoadingUserInfo,
        } = userTieringInfo[ticketId]);
    }

    const handleOverrideGrace = () => {
        setOverrideGraceModal(true);
    };

    const handleOverrideCoolOff = () => {
        setOverrideCoolOffModal(true);
    };

    const handleGraceConfirm = () => {
        const newDate = extendToDate.value;

        if (isNilOrEmpty(newDate)) {
            toastify('Date not selected');
            return;
        }

        const payload = {
            ticketId,
            userId,
            index,
            overrideTimestamp: newDate,
        };
        dispatch(setTieringGrace(payload));
        setOverrideGraceModal(false);
    };

    const handleCoolOffConfirm = () => {
        dispatch(setTieringCoolOff({ ticketId, userId, index }));
        setOverrideCoolOffModal(false);
    };

    const handleCancel = () => {
        setOverrideCoolOffModal(false);
        setOverrideGraceModal(false);
    };

    const handleTieringSensitiveInfo = () => {
        const valueToBeUpdated = !showTieringMovements;
        setShowTieringMovements(valueToBeUpdated);
        if (valueToBeUpdated) {
            if (hasAccessToThePage(activeTicket, SCREEN.LESS_SENSITIVE_SCREEN)) {
                // TODO: once the backend related fix for the Tiering RPC is added this needs comment needs to be removed
                // dispatch(getTieringSensitiveDetails({ ticketId, userId, index }));
            } else {
                dispatch(setAuthUpgradeInfo({ isAuthUpgradeInProgress: true, index }));
                history.push(`${DEFAULT_TICKET_PATH}${ticketId}`);
            }
        }
    };

    const handleDateChange = (value) => {
        setDateSelected(value);
    };

    return (
        <React.Fragment>
            <div className='tiering'>
                { userData && (
                    <React.Fragment>
                        <KVPairInfo data={getTieringList(userData)} extClasses={{ contentWr: 'kvpair-info-cwrl' }} />
                        <div className='forex-txn'>
                            <div className='forex-txn-heading-container'>
                                <div className='forex-txn-heading'>{showTieringMovements ? 'Hide' : 'Show'} Current Plan </div>
                                <Toggle
                                    value={showTieringMovements}
                                    onChange={handleTieringSensitiveInfo}
                                />
                            </div>
                        </div>
                        {showTieringMovements && hasAccessToThePage(activeTicket, SCREEN.LESS_SENSITIVE_SCREEN)
                                && (
                                    <React.Fragment>
                                        <KVPairInfo data={getPlanName(userData)} />
                                        <Table
                                            labelData={TIERING_TABLE_LABEL_DATA}
                                            contentData={userData.movementHistories}
                                            extClasses={{
                                                container: 'mb-60',
                                            }}
                                            errLabel={errInLoadingUserInfo}
                                        />

                                        <div className='frccWrapper'>
                                            {
                                                userData.isUserInGrace === 'TRUE'
                                                    ? (
                                                        <Button
                                                            v2
                                                            primary
                                                            label='Override Grace'
                                                            extClasses='tier-btn'
                                                            onClick={handleOverrideGrace}
                                                        />
                                                    ) : null
                                            }

                                            {
                                                userData.isUserInCoolOff === 'TRUE'
                                                    ? (
                                                        <Button
                                                            v2
                                                            primary
                                                            label='Override Cooloff'
                                                            extClasses='tier-btn'
                                                            onClick={handleOverrideCoolOff}
                                                        />
                                                    ) : null
                                            }
                                        </div>

                                        <Modal
                                            visible={showOverrideGraceModal}
                                            modalStyles={customTieringModalStyles}
                                            contentLabel='Override Grace Period Modal'
                                        >
                                            <div className='heading3 my-30'>Are you sure you want to Override the Grace period?</div>

                                            <SingleDatePicker
                                                key='agent-feedback-from-date'
                                                dateInfo={dateInfo}
                                                toDate={extendToDate}
                                                setToDate={setExtendToDate}
                                            />

                                            <div className='frccWrapper my-30'>
                                                <Button
                                                    v2
                                                    primary
                                                    label='CONFIRM'
                                                    extClasses='tier-btn mr-30'
                                                    onClick={handleGraceConfirm}
                                                />
                                                <Button
                                                    v2
                                                    primary
                                                    label='CANCEL'
                                                    extClasses='tier-btn'
                                                    onClick={handleCancel}
                                                />
                                            </div>
                                        </Modal>

                                        <Modal
                                            visible={showOverrideCoolOffModal}
                                            modalStyles={customTieringModalStyles}
                                            contentLabel='Override CoolOff Period Modal'
                                        >
                                            <div className='heading3 my-30'>Are you sure you want to Override the CoolOff period?</div>
                                            <div className='frccWrapper my-30'>
                                                <Button
                                                    v2
                                                    primary
                                                    label='CONFIRM'
                                                    extClasses='tier-btn'
                                                    onClick={handleCoolOffConfirm}
                                                />

                                                <Button
                                                    v2
                                                    primary
                                                    label='CANCEL'
                                                    extClasses='tier-btn'
                                                    onClick={handleCancel}
                                                />
                                            </div>
                                        </Modal>
                                    </React.Fragment>
                                )}
                        <div className='forex-txn tiering-date-picker'>
                            <div className='forex-txn-heading-container'>
                                <div className='forex-txn-heading'>Select Date for Reward eligibility</div>
                                <FormWithSingleInput
                                    extClasses={{
                                        container: 'pl-fc--m0',
                                        inputField: 'pl-fc__ip',
                                    }}
                                    formType='dob'
                                    input={{
                                        value: dateSelected,
                                        onChange: handleDateChange,
                                        maxDate: new Date(),
                                        minDate: new Date('10-01-2020'),
                                    }}
                                />
                            </div>
                        </div>
                        {userAbuseHistoryData && (
                            <div className='tiering-reward-history-container'>
                                {userAbuseHistoryData.map((historyData) => (
                                    <div className='tiering-reward-history' key={historyData.id}>
                                        <KVPairInfo data={getAbuseHistory(historyData)} />
                                    </div>
                                ))}
                            </div>
                        )}
                        {errInLoadingUserAbuseHistoryData && (
                            <div className='tiering-error-message'>{errInLoadingUserAbuseHistoryData}</div>)}
                        <Table
                            labelData={ELIGIBILITY_TIER_MOVEMENT_DATA}
                            contentData={userData.tierMovemenCriteria}
                            extClasses={{
                                container: 'mb-60',
                            }}
                            errLabel={errInLoadingUserInfo}
                        />
                    </React.Fragment>
                )}
            </div>
            <Loader visible={loadingUserInfo || loadingAbuseHistoryData} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: TIERING_KEY, reducer });
const withSaga = injectSaga({ key: TIERING_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(Tiering);
