/* eslint-disable no-unused-vars */
/**
 * @file Pre Approved Loans - Component to show the Pre Approved Loans related user info, loan info & upcoming EMI info.
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    KVPairInfo, Loader, Table, Button, Modal, ActionDialog, LoaderOrError,
} from '../../../components';

import {
    LOAN_ACCOUNT_DETAILS_LABEL_DATA, LAST_TRANSACTIONS_LABEL_DATA,
    UPCOMING_EMI_DETAILS, LOAN_OFFER_DETAILS_LABEL_DATA, customModalStyles,
} from '../constants';
import { getUserInfo, getLoansInfo, getForeclosureAmount, getLoansAdditionalInfo } from '../actions';
import { getUserInfoList, getUserLoanInfoList } from '../utils';
import { selectUserInfo, selectLoansInfo, selectLoansAdditionalInfo } from '../selectors';

import '../style.scss';

const Loans = (props) => {
    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    // reducer state variables
    const userInfo = useSelector(selectUserInfo, shallowEqual);
    const loanInfo = useSelector(selectLoansInfo, shallowEqual);
    const loanAdditionalInfo = useSelector(selectLoansAdditionalInfo, shallowEqual);
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');

    // variables to control loading status and data
    let loadingUserInfo;
    let userData;
    let userDetails;
    let userDataV2;
    let hasUserData;
    let errInLoadingUserInfo;

    let loadingLoanInfo;
    let loanData;
    let hasLoanData;
    let errInLoadingLoanInfo;
    let data;
    let loanDataV2;

    let hasLoanAdditionalData;
    let loanAdditionalData;
    let loadingLoanAdditionalData;
    let errInLoadingLoanAdditionalData;

    if (userInfo[ticketId]) {
        ({
            loading: loadingUserInfo, data: userDetails, hasData: hasUserData, err: errInLoadingUserInfo,
        } = userInfo[ticketId]);
        ({ userInfo: userData, components: userDataV2 } = userDetails || {});
    }

    if (loanInfo[ticketId]) {
        ({
            loading: loadingLoanInfo, data, hasData: hasLoanData, err: errInLoadingLoanInfo,
        } = loanInfo[ticketId]);
        ({ loanDetails: loanData, components: loanDataV2 } = data || {});
    }

    if (loanAdditionalInfo[ticketId]) {
        ({
            loading: loadingLoanAdditionalData, data: loanAdditionalData, hasData: hasLoanAdditionalData, err: errInLoadingLoanAdditionalData,
        } = loanAdditionalInfo[ticketId]);
    }

    const [showLoanInfoModal, setLoanInfoModal] = useState(false);
    const [pastTransactionsTableData, setPastTransactionsTableData] = useState([]);
    const [upcomingEmisTableData, setUpcomingEmisTableData] = useState([]);

    useEffect(() => {
        if (!userInfo[ticketId]?.hasData) {
            dispatch(getUserInfo({ ticketId, userId, index }));
            dispatch(getLoansInfo({ ticketId, userId, index }));
        }
    }, [dispatch]);

    const handleLoanInfoClick = (itemIndex) => {
        setOpenedActionsCardId('');
        if (loanInfo[ticketId].data) {
            const { pastTransactions, upcomingEmis } = itemIndex;

            setPastTransactionsTableData(pastTransactions);
            setUpcomingEmisTableData(upcomingEmis);
        }

        setLoanInfoModal(true);
    };

    const handleLoanInfoClickV2 = (meta) => {
        const callRpc = () => new Promise((resolve, reject) => {
            try {
                dispatch(getLoansAdditionalInfo({
                    ticketId,
                    meta,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callRpc().then(() => {
            setLoanInfoModal(true);
        });
    };

    const handleModalClose = () => {
        setLoanInfoModal(false);
        setPastTransactionsTableData([]);
        setUpcomingEmisTableData([]);
    };

    const renderActions = (item, idx) => {
        const { loanAccountNumber, loanProgram, vendor } = item;
        return (
            <ActionDialog item={item} openedActionsCardId={openedActionsCardId} setOpenedActionsCardId={setOpenedActionsCardId}>
                <div className='pal-action'>
                    <button
                        onClick={() => {
                            setOpenedActionsCardId('');
                            dispatch(getForeclosureAmount({
                                ticketId, userId, index, loanAccountNumber, loanDetails: { vendor, loanProgram }, idx,
                            }));
                        }}
                        type='button'
                        className='pal-action-label'
                    >
                        Get Foreclosure Amount Now
                    </button>
                    <button
                        onClick={() => handleLoanInfoClick(item)}
                        type='button'
                        className='pal-action-label'
                    >
                        More Details
                    </button>
                </div>
            </ActionDialog>
        );
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const renderActionsV2 = (action) => (item) => {
        const { meta } = item;
        return (
            <div className='frfscWrapper'>
                <Button
                    v2
                    disabled={action.disabled}
                    label={action.label}
                    onClick={() => handleLoanInfoClickV2(meta)}
                />
            </div>
        );
    };

    const renderComponent = (component) => {
        const { table, key_value_component: section, details } = component;

        switch (details) {
            case 'key_value_component': {
                return (
                    <React.Fragment>
                        <div className='pal-th'>{section?.title}</div>
                        <KVPairInfo data={section?.label_values} v2 />
                    </React.Fragment>
                );
            }
            case 'table': {
                return (
                    <React.Fragment>
                        <div className='pal-th'>{table?.tableName}</div>
                        <Table
                            rowDataVersion={2}
                            renderActionableColumn={
                                (table?.actions && Array.isArray(table?.actions) && table?.actions.length === 1)
                                    ? renderActionsV2(table?.actions[0]) : null
                            }
                            labelData={table?.columnData}
                            contentData={table?.rowData}
                            errLabel={errInLoadingUserInfo}
                            extClasses={{
                                container: 'mb-60',
                            }}
                        />
                    </React.Fragment>
                );
            }
            default: {
                return '';
            }
        }
    };

    if (loanData) {
        return (
            <React.Fragment>
                <div className='pal'>
                    {/* User Details */}
                    { userInfo[ticketId]?.hasData && (
                        <React.Fragment>
                            <div className='pal-th'>Current Month User Loan Eligibility:</div>
                            <KVPairInfo data={getUserInfoList(userData)} />
                            <div className='pal-th'>Latest Loan Application Details:</div>
                            <KVPairInfo data={getUserLoanInfoList(userData)} />
                            <div className='pal-th'>Loan Offer Details</div>
                            <Table
                                labelData={LOAN_OFFER_DETAILS_LABEL_DATA}
                                contentData={userData.loanOfferDetails}
                                errLabel={errInLoadingUserInfo}
                                extClasses={{
                                    container: 'mb-60',
                                }}
                            />
                        </React.Fragment>
                    )}

                    {/* Loan and EMI Details */}
                    { userInfo[ticketId]?.data && (
                        <React.Fragment>
                            <div className='pal-th'>Loan Account Details</div>
                            <Table
                                labelData={LOAN_ACCOUNT_DETAILS_LABEL_DATA}
                                contentData={loanData}
                                extClasses={{
                                    container: 'mb-60',
                                }}
                                errLabel={errInLoadingLoanInfo}
                                renderActionableColumn={renderActions}
                            />

                            <Modal
                                visible={showLoanInfoModal}
                                modalStyles={customModalStyles}
                                contentLabel='Loan Info'
                            >
                                {upcomingEmisTableData && (
                                    <React.Fragment>
                                        <div className='pal-th'>Upcoming EMI Details</div>
                                        <Table
                                            labelData={UPCOMING_EMI_DETAILS}
                                            contentData={upcomingEmisTableData}
                                            errLabel={errInLoadingLoanInfo}
                                            extClasses={{
                                                container: 'mb-60',
                                            }}
                                        />
                                    </React.Fragment>
                                )}

                                {pastTransactionsTableData && (
                                    <React.Fragment>
                                        <div className='pal-th'>Last 5 Transactions</div>
                                        <Table
                                            labelData={LAST_TRANSACTIONS_LABEL_DATA}
                                            contentData={pastTransactionsTableData}
                                            errLabel={errInLoadingLoanInfo}
                                            extClasses={{
                                                container: 'mb-60',
                                            }}
                                        />
                                    </React.Fragment>
                                )}

                                <Button
                                    v2
                                    primary
                                    label='Close'
                                    extClasses='pal-btn'
                                    onClick={handleModalClose}
                                />
                            </Modal>
                        </React.Fragment>
                    )}

                </div>
                <Loader visible={loadingUserInfo} />
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className='pal'>
                {/* User Details */}
                {
                    hasUserData && Array.isArray(userDataV2) && userDataV2.length > 0 && (
                        <React.Fragment>
                            {
                                userDataV2.map(renderComponent)
                            }
                        </React.Fragment>
                    )
                }

                {/* Loan and EMI Details */}
                {
                    hasLoanData && Array.isArray(loanDataV2) && loanDataV2.length > 0 && (
                        <React.Fragment>
                            {
                                loanDataV2.map(renderComponent)
                            }
                        </React.Fragment>
                    )
                }
                <Modal
                    visible={showLoanInfoModal}
                    modalStyles={customModalStyles}
                    contentLabel='Loan Info'
                >
                    {
                        hasLoanAdditionalData && Array.isArray(loanAdditionalData) && loanAdditionalData.length > 0 && (
                            <React.Fragment>
                                {
                                    loanAdditionalData.map(renderComponent)
                                }
                            </React.Fragment>
                        )
                    }
                    <Button
                        v2
                        primary
                        label='Close'
                        extClasses='pal-btn'
                        onClick={handleModalClose}
                    />
                </Modal>
            </div>
            <LoaderOrError
                loading={loadingUserInfo || loadingLoanInfo || loadingLoanAdditionalData}
                errLabel={errInLoadingUserInfo || errInLoadingLoanInfo || errInLoadingLoanAdditionalData}
            />
        </React.Fragment>
    );
};

export default Loans;
