import {
    APP_LOGS_PATH, DB_STATES_PATH, TICKET_SUMMARY_PATH, DEV_ACTIONS_PATH, LIVENESS_VIDEO_PATH,
    FM_KYC_IMAGE_PATH, LIVENESS_SUMMARY_PATH, LIVENESS_STATUS_PATH, FIT_RULES_PATH, LIVENESS_REVIEW_PATH,
    FACEMATCH_REVIEW_PATH, NAMEMATCH_REVIEW_PATH, WEALTH_OPS_PATH, CUSTOMER_DETAILS_PATH, LIVENESS_AND_FACEMATCH_REVIEW_PATH,
    CX_RISK_REVIEW_PATH, USER_RISK_REVIEW_PATH, US_STOCKS_FACEMATCH_REVIEW_PATH, RISK_CASE_USER_PATH, RISK_CASE_TXN_PATH,
    RISK_RULE_PATH, RISK_CASE_SEARCH_PATH, ESCALATION_REVIEW_PATH, DEVELOPER_BANKING_PROFILE_PATH, DEVELOPER_BANKING_SAVINGS_ACCOUNT_PATH,
    MULTI_QUEUE_REVIEW_PATH, NR_MANUAL_REVIEW_PATH,
} from '../../App/routes';

export const getMenuStructure = ({
    isDeveloper, isViewLivenessVideo, isRiskOps,
    isFitAdmin, isRecurringPaymentDev, isRiskOpsTxn, isRiskEng,
    isWealthOps, isRewardsAdmin,
    isDataOps, isJumpOps, isJumpSensitiveVendorResponse,
    isUsStocksOps, isKycAnalyst, isFinanceAdmin,
    isRiskOpsAdmin, isUserOutcall, isKycAgentAdmin, isDataRetriever,
    isSalaryAdmin, isSegmentationAdmin, isUsstocksSensitiveData,
    isCxSupportLlmTester, isStockGuardianLendingOps, isStockGuardianDeveloper, isEscalations,
}) => [
    {
        id: 'app-logs',
        label: 'App Logs',
        path: APP_LOGS_PATH,
        hidden: isRiskOps || isWealthOps || isRewardsAdmin || isDataOps || isJumpOps || isJumpSensitiveVendorResponse || isUsStocksOps
        || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
        || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper
        || isEscalations,
    },
    {
        id: 'db-states',
        label: 'DB States',
        path: DB_STATES_PATH,
        hidden: isRiskOps || isWealthOps || isRewardsAdmin || isDataOps || isJumpOps || isJumpSensitiveVendorResponse || isUsStocksOps
        || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
        || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isEscalations,
    },
    {
        id: 'ticket-summary',
        label: 'Ticket Summary',
        path: TICKET_SUMMARY_PATH,
        hidden: isRewardsAdmin || isDataOps || isJumpOps || isJumpSensitiveVendorResponse
        || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
        || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper
        || isEscalations,
    },
    {
        id: 'dev-actions',
        label: 'Dev Actions',
        path: DEV_ACTIONS_PATH,
        hidden: isKycAnalyst || isRiskOpsTxn || isUserOutcall,
    },
    {
        id: 'liveness-summary',
        label: 'Liveness Summary',
        path: LIVENESS_SUMMARY_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin
        || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst
        || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin
        || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'customer-details',
        label: 'Customer Details',
        path: CUSTOMER_DETAILS_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps
        || isJumpOps || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin
        || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
         || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'NROps',
        label: 'NR Manual Review',
        path: NR_MANUAL_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo
        || isFitAdmin || isRecurringPaymentDev || isRiskOpsTxn || isRiskEng
        || isWealthOps || isRewardsAdmin
        || isDataOps || isJumpOps || isJumpSensitiveVendorResponse
        || isUsStocksOps || isKycAnalyst || isFinanceAdmin
        || isRiskOpsAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever
        || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'liveness-video',
        label: 'Liveness Video',
        path: LIVENESS_VIDEO_PATH,
        hidden: isDeveloper || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin
        || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
         || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'fm-kyc-image',
        label: 'Facematch/KYC Image',
        path: FM_KYC_IMAGE_PATH,
        hidden: isDeveloper || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin
        || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
         || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'liveness-status',
        label: 'Liveness Status',
        path: LIVENESS_STATUS_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps
        || isJumpOps || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin
        || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
         || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'fit-rules',
        label: 'Fit Rules',
        path: FIT_RULES_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isRiskOps || isRecurringPaymentDev || isWealthOps || isRewardsAdmin
        || isDataOps || isJumpOps || isJumpSensitiveVendorResponse
         || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
         || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper
        || isEscalations,
    },
    {
        id: 'liveness-review',
        label: 'Liveness Review',
        path: LIVENESS_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'facematch-review',
        label: 'Facematch Review',
        path: FACEMATCH_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'namematch-review',
        label: 'Namematch Review',
        path: NAMEMATCH_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'liveness-and-facematch-review',
        label: 'Liveness & Facematch Review',
        path: LIVENESS_AND_FACEMATCH_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isDataOps || isJumpOps || isJumpSensitiveVendorResponse
        || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
        || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper
        || isEscalations,
    },
    {
        id: 'cx-risk-review',
        label: 'CX Risk Review',
        path: CX_RISK_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isDataOps || isJumpOps || isJumpSensitiveVendorResponse
        || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin || isDataRetriever || isSalaryAdmin
        || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper
        || isEscalations,
    },
    {
        id: 'user-risk-review',
        label: 'User Risk Review',
        path: USER_RISK_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin
         || isRecurringPaymentDev || isDataOps || isUsStocksOps
         || isKycAnalyst || isUserOutcall
        || isRiskOpsTxn || isFinanceAdmin || isKycAgentAdmin || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData
         || isCxSupportLlmTester || isStockGuardianLendingOps || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'wealth-ops',
        label: 'Wealth Ops',
        path: WEALTH_OPS_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRiskOps || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isUsStocksOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'uss-facematch-review',
        label: 'USStocks Facematch Review',
        path: US_STOCKS_FACEMATCH_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRiskOps || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isKycAnalyst || isRiskOpsTxn || isFinanceAdmin || isUserOutcall || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'transaction-queue',
        label: 'Transaction Queue',
        path: RISK_CASE_TXN_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isUsStocksOps || isKycAnalyst || isFinanceAdmin || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'escalation-review',
        label: 'Escalation Review',
        path: ESCALATION_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isUsStocksOps || isKycAnalyst || isFinanceAdmin || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'multi-queue',
        label: 'Multi Queue',
        path: MULTI_QUEUE_REVIEW_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isUsStocksOps || isKycAnalyst || isFinanceAdmin || isKycAgentAdmin
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'kyc-queue',
        label: 'KYC Review',
        path: RISK_CASE_USER_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isUsStocksOps || isRiskOpsTxn || isFinanceAdmin || isKycAgentAdmin || isDataRetriever
        || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'case-search',
        label: 'Case Search',
        path: RISK_CASE_SEARCH_PATH,
        hidden: isDeveloper || isViewLivenessVideo || isFitAdmin || isRecurringPaymentDev || isRewardsAdmin || isDataOps || isJumpOps
        || isJumpSensitiveVendorResponse || isWealthOps || isUsStocksOps || isRiskOpsTxn || isFinanceAdmin || isKycAgentAdmin || isUserOutcall
        || isDataRetriever || isSalaryAdmin || isSegmentationAdmin || isUsstocksSensitiveData || isCxSupportLlmTester || isStockGuardianLendingOps
        || isStockGuardianDeveloper || isEscalations,
    },
    {
        id: 'risk-rules',
        label: 'Risk Rules',
        path: RISK_RULE_PATH,
        hidden: !isRiskOpsAdmin && !isRiskEng,
    },
    {
        id: 'banking',
        label: 'Banking',
        hidden: !isDataRetriever && !isSalaryAdmin,
        nestedLinks: [
            {
                id: 'profile',
                label: 'Profile',
                path: DEVELOPER_BANKING_PROFILE_PATH,
            },
            {
                id: 'savings-account',
                label: 'Saving Accounts',
                path: DEVELOPER_BANKING_SAVINGS_ACCOUNT_PATH,
            },
        ],
    },
];
