import React, { useContext, useEffect } from 'react';
import { Table } from '../../../components';
import CorrectWrongIcon from '../../../components/CorrectWrongIcon';
import ImageUtils, { AutoScaleImageViewer } from '../../../utils/imageUtils';
import ProgressBar from '../../../components/ProgressBar';
import { UserDetailsContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import { matchScoreFormatting } from '../utils/serverCodeFormatter';

const ReportDetails = (props) => {
    const {
        reportData, handleImageReload, shouldShowRemarks = false, meetingId,
    } = props;
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const {
        user_details: userDetails, question_answers: livenessQuestionAnswers, location_check_report: locationReport,
        browser_and_ip_details: browserAndIpDetails, face_match_results: faceMatchResults, address_match_result: addressMatchResult,
        verified_document_details: documentDetails, agent_remarks: agentRemarks,
    } = reportData || {};

    const { image_match_results: imageMatchResults } = faceMatchResults || {};

    useEffect(() => {
        pushRudderEvent(RudderEvent.VKYC.VKYCReportDetailsLoaded, agentInfo.emailId, {
            MeetingId: meetingId,
            currentStep: 'Report details',
        });
    }, []);

    return (
        <div className='vkyc-auditor-details-table-container'>
            {
                reportData?.user_details && (
                    <Table
                        rowDataVersion={2}
                        objKey='header_key'
                        labelData={userDetails?.columnData}
                        contentData={userDetails?.rowData}
                        // renderActionableColumn={renderActions}
                        prevToken={null}
                        nextToken={null}
                        paginatedClickHandler={() => { }}
                        extClasses={{
                            container: 'vkyc-auditor-details-table',
                            headerCr: 'vkyc-auditor-details-table-header',
                            contentCr: 'vkyc-auditor-details-table-content',
                        }}
                    />
                )
            }
            {/* this container container contains all the different components which are sent from BE for the final report
                 and it only show components which are not null  */}
            <div className='vkyc-final-scr-report-cr'>
                {
                    imageMatchResults && Array.isArray(imageMatchResults) && imageMatchResults.map(({
                        title, reference_image_details: referenceImage, input_image_details: inputImage, match_score_percent: matchScore,
                    } = {}) => (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>{title}</div>
                            <div className='vkyc-image-comparison-img-container'>
                                <AutoScaleImageViewer
                                    url={referenceImage?.presigned_read_url}
                                    baseContainerClass='vkyc-image-placeholder'
                                    baseImageClass='vkyc-image'
                                    landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                    landscapeImageClass='vkyc-image-horizontal'
                                    handleReload={handleImageReload && handleImageReload(title)}
                                    meetingId={meetingId}
                                    stageName='Report Details'
                                />
                                <div className='vkyc-image-report-placeholder'>
                                    <ImageUtils
                                        url={inputImage?.presigned_read_url}
                                        containerClass='vkyc-auditor-image'
                                        handleImageReload={handleImageReload && handleImageReload(title)}
                                    />
                                </div>
                            </div>
                            <div className='vkyc-image-comparison-match-score'>
                                <ProgressBar
                                    number={matchScore || 0}
                                    progressBarStyleClassName={
                                        matchScore >= 50 ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                    }
                                />
                                {matchScoreFormatting(matchScore)}%
                            </div>
                        </div>
                    ))
                }
                {
                    locationReport?.formatted_location_details && Array.isArray(locationReport?.formatted_location_details) && (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>Location Check</div>
                            {
                                locationReport?.formatted_location_details?.map(({
                                    label, expected_answer: expectedAnswer, is_valid: isValid,
                                }) => (
                                    <div className='vkyc-qna-container'>
                                        <div className='frcsbWrapper'>
                                            <div className=''>
                                                <div className='sop-step-title'>{label}</div>
                                                <div className='sop-step-des'>{expectedAnswer}</div>
                                            </div>
                                            <div className=''>
                                                <CorrectWrongIcon isCorrect={isValid} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                {
                    addressMatchResult && (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>{addressMatchResult?.title}</div>
                            <div className='vkyc-image-comparison-img-container'>
                                <AutoScaleImageViewer
                                    url={addressMatchResult?.reference_image_details?.presigned_read_url}
                                    baseContainerClass='vkyc-image-placeholder'
                                    baseImageClass='vkyc-image'
                                    landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                    landscapeImageClass='vkyc-image-horizontal'
                                    handleReload={handleImageReload && handleImageReload(addressMatchResult?.title)}
                                    meetingId={meetingId}
                                    stageName='Report Details'
                                />
                                <div className='vkyc-image-placeholder vkyc-match-text'>
                                    {addressMatchResult?.permanent_address}
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    livenessQuestionAnswers && Array.isArray(livenessQuestionAnswers) && (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>Liveness Check</div>
                            {livenessQuestionAnswers?.filter((qna) => qna?.string_answer).map(({
                                question, string_answer: stringAnswer,
                            } = {}) => (
                                <div className='vkyc-qna-container'>
                                    <div className='frcsbWrapper'>
                                        <div className=''>
                                            <div className='vkyc-auditor-liveness-details-label'>{question}</div>
                                            <div className='vkyc-auditor-liveness-details-value'>{stringAnswer}</div>
                                        </div>
                                        <div className=''>
                                            <CorrectWrongIcon isCorrect />
                                            {/* {answers[index] && <CorrectWrongIcon isCorrect={answers[index] === QUESTION_ANSWER_VALUE.YES} />} */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                }
                {
                    documentDetails && Array.isArray(documentDetails) && documentDetails
                        ?.filter((documentDetail) => documentDetail?.front_image || documentDetail?.back_image)
                        ?.map(({
                            title, front_image: frontImage, back_image: backImage,
                        } = {}) => (
                            <div className='vkyc-image-comparison-container'>
                                <div className='vkyc-image-comparison-title'>{title}</div>
                                <div className='vkyc-image-comparison-img-container'>
                                    {
                                        frontImage && (
                                            <AutoScaleImageViewer
                                                url={frontImage?.presigned_read_url}
                                                baseContainerClass='vkyc-image-placeholder'
                                                baseImageClass='vkyc-image'
                                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                                landscapeImageClass='vkyc-image-horizontal'
                                                handleReload={handleImageReload && handleImageReload(`${title} : Front`)}
                                                meetingId={meetingId}
                                                stageName='Report Details'
                                            />
                                        )
                                    }
                                    {
                                        backImage && (
                                            <AutoScaleImageViewer
                                                url={backImage?.presigned_read_url}
                                                baseContainerClass='vkyc-image-placeholder'
                                                baseImageClass='vkyc-image'
                                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                                landscapeImageClass='vkyc-image-horizontal'
                                                handleReload={handleImageReload && handleImageReload(`${title} : Back`)}
                                                meetingId={meetingId}
                                                stageName='Report Details'
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        ))
                }
                {
                    browserAndIpDetails && (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>Browser & IP Details</div>
                            <div className='vkyc-qna-container'>
                                <div className='frcsbWrapper'>
                                    <div className=''>
                                        <div className='vkyc-auditor-liveness-details-label'>ISP</div>
                                        <div className='vkyc-auditor-liveness-details-v'>
                                            {browserAndIpDetails?.internet_service_provider}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <CorrectWrongIcon isCorrect />
                                    </div>
                                </div>
                            </div>
                            <div className='vkyc-qna-container'>
                                <div className='frcsbWrapper'>
                                    <div className=''>
                                        <div className='vkyc-auditor-liveness-details-label'>IP Country Code</div>
                                        <div className='vkyc-auditor-liveness-details-value'>
                                            {browserAndIpDetails?.ip_country_code}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <CorrectWrongIcon isCorrect />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    agentRemarks && shouldShowRemarks && (
                        <div className='vkyc-image-comparison-container'>
                            <div className='vkyc-image-comparison-title'>Agent Remarks</div>
                            <div className='vkyc-qna-container'>
                                <div className='frcsbWrapper'>
                                    <div className=''>
                                        <div className='vkyc-auditor-liveness-details-label'>{agentRemarks}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ReportDetails;
