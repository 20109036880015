/**
 * @file Manages the action functions for Loans Out call
 */

import {
    GET_LOANS_OUT_CALL_USER_DETAILS, GET_LOANS_OUT_CALL_LOANS_DETAILS, GET_LOANS_OUT_CALL_ADDITIONAL_INFO,
    GET_LOANS_OUT_CALL_TICKETS, SET_LOANS_OUT_CALL_TICKET_ID, SET_LOANS_OUT_CALL_TICKETS, ERR_LOANS_OUT_CALL_TICKETS,
    GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS, GET_LOANS_OUT_CALL_USER_TICKET_HISTORY, SET_LOANS_OUT_CALL_USER_TICKET_HISTORY,
    ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY, SET_LOANS_OUT_CALL_USER_DETAILS, ERR_LOANS_OUT_CALL_USER_DETAILS,
    SET_LOANS_OUT_CALL_LOANS_DETAILS, ERR_LOANS_OUT_CALL_LOANS_DETAILS, SET_LOANS_OUT_CALL_ADDITIONAL_INFO,
    ERR_LOANS_OUT_CALL_ADDITIONAL_INFO, SET_LOANS_OUT_CALL_USER_PROFILE_DETAILS, ERR_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
} from './constants';

export const getUserInfo = (data) => ({
    type: GET_LOANS_OUT_CALL_USER_DETAILS,
    data,
});

export const setUserInfo = (data) => ({
    type: SET_LOANS_OUT_CALL_USER_DETAILS,
    data,
});

export const errUserInfo = (data) => ({
    type: ERR_LOANS_OUT_CALL_USER_DETAILS,
    data,
});

export const getLoansInfo = (data) => ({
    type: GET_LOANS_OUT_CALL_LOANS_DETAILS,
    data,
});

export const setLoansInfo = (data) => ({
    type: SET_LOANS_OUT_CALL_LOANS_DETAILS,
    data,
});

export const errLoansInfo = (data) => ({
    type: ERR_LOANS_OUT_CALL_LOANS_DETAILS,
    data,
});

export const getLoansAdditionalInfo = (data) => ({
    type: GET_LOANS_OUT_CALL_ADDITIONAL_INFO,
    data,
});

export const setLoansAdditionalInfo = (data) => ({
    type: SET_LOANS_OUT_CALL_ADDITIONAL_INFO,
    data,
});

export const errLoansAdditionalInfo = (data) => ({
    type: ERR_LOANS_OUT_CALL_ADDITIONAL_INFO,
    data,
});

export const setLoansOutCallTicketId = (data) => ({
    type: SET_LOANS_OUT_CALL_TICKET_ID,
    data,
});

export const getLoansOutCallTickets = (data) => ({
    type: GET_LOANS_OUT_CALL_TICKETS,
    data,
});

export const setLoansOutCallTickets = (data) => ({
    type: SET_LOANS_OUT_CALL_TICKETS,
    data,
});

export const errLoansOutCallTickets = (data) => ({
    type: ERR_LOANS_OUT_CALL_TICKETS,
    data,
});

export const getUserProfileInfoDetails = (data) => ({
    type: GET_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
    data,
});

export const setUserProfileInfoDetails = (data) => ({
    type: SET_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
    data,
});

export const errUserProfileInfoDetails = (data) => ({
    type: ERR_LOANS_OUT_CALL_USER_PROFILE_DETAILS,
    data,
});

export const getLoansOutCallUserTicketHistory = (data) => ({
    type: GET_LOANS_OUT_CALL_USER_TICKET_HISTORY,
    data,
});

export const setLoansOutCallUserTicketHistory = (data) => ({
    type: SET_LOANS_OUT_CALL_USER_TICKET_HISTORY,
    data,
});

export const errLoansOutCallUserTicketHistory = (data) => ({
    type: ERR_LOANS_OUT_CALL_USER_TICKET_HISTORY,
    data,
});
