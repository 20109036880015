/**
 * @file UserProfileInfo - Component to show the user profile info
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, Modal, LoaderOrError } from '../../../components';

import { selectLoansAdditionalInfo, selectUserProfileInfo, selectLoansOutCallTicketId } from '../selectors';
import { getUserProfileInfoDetails, getLoansAdditionalInfo } from '../actions';

import { customModalStyles } from '../constants';
import RenderComponent from '../RenderComponent';

const UserProfileInfo = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const userInfo = useSelector(selectUserProfileInfo, shallowEqual);
    const loanAdditionalInfo = useSelector(selectLoansAdditionalInfo, shallowEqual);
    const ticketId = useSelector(selectLoansOutCallTicketId, shallowEqual);

    const [showLoanInfoModal, setLoanInfoModal] = useState(false);

    const {
        loading: loadingUserInfo, data: userData, err: errInLoadingUserInfo,
    } = userInfo?.[ticketId] || {};

    const {
        loading: loadingLoanAdditionalData, data: loanAdditionalData, err: errInLoadingLoanAdditionalData,
    } = loanAdditionalInfo || {};

    useEffect(() => {
        dispatch(getUserProfileInfoDetails({
            ticketId, screenKey: ['userProfileInfo', ticketId],
        }));
    }, [dispatch]);

    const handleTableActionClick = () => (meta) => {
        const callRpc = () => new Promise((resolve, reject) => {
            try {
                dispatch(getLoansAdditionalInfo({
                    ticketId,
                    meta,
                    screenKey: 'loanAdditionalInfo',
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callRpc().then(() => {
            setLoanInfoModal(true);
        });
    };

    return (
        <React.Fragment>
            <div className='p-20' style={{ width: '100%', maxWidth: '1000px' }}>
                {/* User Details */}
                {
                    userData && Array.isArray(userData) && userData.length > 0 && (
                        <React.Fragment>
                            {
                                userData.map((component) => (
                                    <RenderComponent
                                        component={component}
                                        tableErrLabel={errInLoadingUserInfo}
                                        handleTableActionClick={handleTableActionClick}
                                    />
                                ))
                            }
                        </React.Fragment>
                    )
                }
                <Modal
                    visible={showLoanInfoModal}
                    modalStyles={customModalStyles}
                    contentLabel='Loan Info'
                >
                    {
                        loanAdditionalData && Array.isArray(loanAdditionalData) && loanAdditionalData.length > 0 && (
                            <React.Fragment>
                                {
                                    loanAdditionalData.map((component) => (
                                        <RenderComponent
                                            component={component}
                                        />
                                    ))
                                }
                            </React.Fragment>
                        )
                    }
                    <Button
                        v2
                        primary
                        label='Close'
                        extClasses='pal-btn'
                        onClick={() => setLoanInfoModal(false)}
                    />
                </Modal>
            </div>
            <LoaderOrError
                loading={loadingUserInfo || loadingLoanAdditionalData}
                errorLabel={errInLoadingUserInfo || errInLoadingLoanAdditionalData}
            />
        </React.Fragment>
    );
};

export default UserProfileInfo;
