/**
 * @file Transaction View V2
 * Page to view the transaction details in charts and table
 */

import React, {
    useEffect, useState, useRef, useMemo, useContext,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, DateRangePicker, ExpandableCard, FormDropdown, ComponentLoader,
} from '../../components';
import { getInitialDateRange } from '../../utils';

import {
    AggregateOptions, YValueOptions,
    cdTypeOptions, dateInfo, DATE_RANGE_KEY,
    PeriodOptions, defaultAggergation, defaultYValue,
    defaultCdType,
    customFilterPeriod,
    TransactionViewMetricsEvent,
    ANNOTATION_MAP,
} from './constants';
import {
    selectTransactionViewV2Details, selectTransactionViewError,
    selectTransactionViewLoader, selectTransactionFetchingStatus, selectTransactionViewPeriod, selectApp,
    selectFilterValue,
} from './selectors';
import { selectTransactionCaseDetails } from '../TransactionCaseDetails/selectors';
import { getTransactionViewV2Action, setTransactionViewV2PeriodAction, fireRenderEvent, setFilterField } from './actions';
import KeyTransactionTrends from './KeyTransactionTrends';
import { convertMillisecondsToTimestamp, getFromDateBasedOnPeriod, getStartOrEndTime } from './utils';
import TransactionTable from './TransactionTable';
import DebitCreditChart from './DebitCreditChart';
import './style.scss';
import { clearPersistedData } from './TransactionTable/utils';
import { TRANSACTION_REVIEW_V2_FILTERS } from './TransactionTable/constants';
import { useTransactionViewEvent } from './useTransactionViewEvent';
import CustomChart from './CustomChart';
import AnnotationFormUi from './TransactionAnnotationModal/form';
import { MetaInfoContext } from '../../context';

const renderEventDelayTime = 1000;

const transactionViewEventState = new TransactionViewMetricsEvent();

const TransactionViewV2 = (props) => {
    const { actorId } = props;

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);

    const {
        riskOps: {
            transactionViewV2Params: {
                lifetimeCreditDebitTrend: {
                    enable: lifetimeCreditDebitTrendEnable,
                },
                creditDebitTrends: {
                    enable: creditDebitTrendsEnable,
                    isExpanded: creditDebitTrendsExpanded,
                },
                keyTransactionTrends: {
                    enable: keyTransactionTrendsEnable,
                    isExpanded: keyTransactionTrendsExpanded,
                },
                transactionHistoryTable: {
                    enable: transactionHistoryTableEnable,
                    isExpanded: transactionHistoryTableExpanded,
                },
            },
        },
    } = metaInfo;

    const period = useSelector(selectTransactionViewPeriod, shallowEqual);
    const data = useSelector(selectTransactionViewV2Details, shallowEqual);
    const isLoading = useSelector(selectTransactionViewLoader, shallowEqual);
    const isfetching = useSelector(selectTransactionFetchingStatus, shallowEqual);
    const error = useSelector(selectTransactionViewError, shallowEqual);

    const canRenderChartsAndTable = (!isLoading && !error && !!data.length);

    const [Aggregation, setAggregation] = useState(() => defaultAggergation);
    const [YValue, setYValue] = useState(() => defaultYValue);
    const [cdType, setCdType] = useState(() => defaultCdType);
    const { toDate, fromDate } = useSelector(selectFilterValue, shallowEqual);

    const [isExpanded, setIsExpanded] = useState(creditDebitTrendsExpanded);
    const [isExpandKeyTranTrends, setExpandKeyTranTrends] = useState(keyTransactionTrendsExpanded);
    const [isExpandTransHistory, setExpandTransHistory] = useState(transactionHistoryTableExpanded);
    const [trendAnnotationVisibility, setTrendAnnotationVisibility] = useState(true);
    const observer = useRef();
    const transactionTableRef = useRef();
    const keyTransactionTrendsChartRef = useRef();
    const transactionCaseDetails = useSelector(selectTransactionCaseDetails, shallowEqual);
    const transactionViewEvent = useTransactionViewEvent();

    const caseId = useMemo(() => transactionCaseDetails?.caseDetails?.find((item) => item?.id)?.value, [transactionCaseDetails]);

    const renderEventTimerRef = useRef();

    const { agentInfo } = useSelector(selectApp, shallowEqual);

    const eventRenderRef = useRef({
        prevValue: -1,
        value: null,
        timeStamp: 0,
        startTime: 0,
    });

    // Todo : Improving lazy loading by using intersection observer.

    const interSectionObservabilityIntersection = () => {
    };

    const setCustomPeriod = () => {
        dispatch(setTransactionViewV2PeriodAction(customFilterPeriod));
    };

    const setToDate = (value) => {
        dispatch(setFilterField({ toDate: value }));
        setCustomPeriod();
    };

    const setFromDate = (value) => {
        dispatch(setFilterField({ fromDate: value }));
        setCustomPeriod();
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '100px',
            threshold: 0,
        };
        observer.current = new IntersectionObserver(interSectionObservabilityIntersection, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (transactionTableRef.current) {
        observer.current.observe(transactionTableRef.current);
    }

    if (keyTransactionTrendsChartRef.current) {
        observer.current.observe(keyTransactionTrendsChartRef.current);
    }

    const onboardingDate = null;

    const toggleExpand = () => {
        setIsExpanded((pre) => !pre);
    };

    const toggleExpandKeyTranTrends = () => {
        setExpandKeyTranTrends((pre) => !pre);
    };

    const toggleExpandTranHistory = () => {
        setExpandTransHistory((pre) => !pre);
    };

    const toggleExpandTrendAnnotation = () => {
        setTrendAnnotationVisibility((pre) => !pre);
    };

    const getTransactionDetails = () => {
        // set hours to start of the day in milliseonds
        const fromTime = getStartOrEndTime(fromDate.value);
        // set hours to end of the day in milliseonds
        const toTime = getStartOrEndTime(toDate.value, 'end');

        dispatch(getTransactionViewV2Action({
            payload: {
                actor_id: actorId,
                from_time: convertMillisecondsToTimestamp(fromTime),
                to_time: convertMillisecondsToTimestamp(toTime),
                case_id: caseId,
            },
            eventPayload: {
                agentMail: agentInfo.emailId,
                caseId,
            },
        }));
    };

    const handleSubmit = () => {
        eventRenderRef.current = {
            prevValue: -1,
            timeStamp: null,
            startTime: 0,
        };
        getTransactionDetails();
    };

    const getDateDifferenceinDays = (dateA, dateB) => {
        const differenceInMs = Math.abs(dateA - dateB);
        return Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    };

    useEffect(() => {
        if (actorId && fromDate && toDate) {
            const days = getDateDifferenceinDays(toDate.value, fromDate.value);
            const selectedPeriod = PeriodOptions.filter((periodValue) => periodValue.days === days);
            if (selectedPeriod.length > 0) {
                dispatch(setTransactionViewV2PeriodAction(selectedPeriod[0]));
            } else {
                setCustomPeriod();
            }
        }

        return () => {
            if (renderEventTimerRef.current) clearTimeout(renderEventTimerRef.current);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangePeriod = (option) => {
        if (period.value !== option.value && option.value != null) {
            const defaultFromDate = getFromDateBasedOnPeriod(option.value);

            const dateRange = getInitialDateRange(null, true, {
                defaultFromDate,
            });
            dispatch(setTransactionViewV2PeriodAction(option));

            dispatch(setFilterField({ toDate: dateRange.toDate }));

            dispatch(setFilterField({ fromDate: dateRange.fromDate }));
        } else {
            dispatch(setTransactionViewV2PeriodAction(option));
        }
    };

    useEffect(() => {
        initializeConfig();
    }, []);

    const initializeConfig = () => {
        clearPersistedData(TRANSACTION_REVIEW_V2_FILTERS);
    };

    const renderingEvent = (time) => {
        /**
        * @function : tracking requestAnimationFrame to measure total render
        * matric, event will only once will be triggerd in trasaction view page.
        * eventRenderRef ref is used to store required to trigger required values.
        * setInterval is used to check the painting of component is done.
        */

        if (renderEventTimerRef.current) clearTimeout(renderEventTimerRef.current);

        if (!eventRenderRef.current.timeStamp) {
            eventRenderRef.current.startTime = time;
        }

        eventRenderRef.current.prevValue = eventRenderRef.current.timeStamp;
        eventRenderRef.current.timeStamp = time;

        renderEventTimerRef.current = setTimeout(() => {
            if (eventRenderRef.current.prevValue === eventRenderRef.current.timeStamp) {
                dispatch(fireRenderEvent({
                    startTime: Math.round(eventRenderRef.current.startTime),
                    endTime: Math.round(eventRenderRef.current.timeStamp),
                    agentMail: agentInfo.emailId,
                    caseId,
                }));
            }
        }, renderEventDelayTime);
    };

    const fireTvPerformanceMetricEvent = (eventName, payload) => {
        transactionViewEvent.fireTransactionViewEvents(eventName, {
            caseId,
            ...payload,
        });
    };

    return (
        <div className='trv2-c'>
            <div className='mb-30'>
                <div className='frccWrapper trv2-cs trv2-dpc'>
                    <FormDropdown
                        label='Period'
                        options={PeriodOptions}
                        input={{
                            value: period,
                            onChange: handleOnChangePeriod,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='transaction-view-period'
                        extStyles={{
                            container: 'frcsbWrapper trv2-cs trv2-pdc',
                        }}
                    />
                    <DateRangePicker
                        key={DATE_RANGE_KEY}
                        dateInfo={dateInfo}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                    />
                    <Button
                        v2
                        type='button'
                        primary
                        label='Submit'
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            <div className='trv2-body'>
                <div className='chart_container'>
                    {lifetimeCreditDebitTrendEnable
                    && (
                        <CustomChart
                            caseId={caseId}
                            yValue='value'
                            aggregation={defaultAggergation.value}
                            onboardingDate={null}
                        />
                    )}
                </div>
                <div className='trv2-opt-box'>
                    {
                        canRenderChartsAndTable
                            ? (
                                <div className='frccWrapper trv2-cs mt-20'>
                                    <FormDropdown
                                        label='Aggregate'
                                        options={AggregateOptions}
                                        input={{
                                            value: Aggregation,
                                            onChange: setAggregation,
                                            placeholder: 'Choose...',
                                        }}
                                        cacheKey='transaction-view-aggregate'
                                        extStyles={{
                                            container: 'frcsbWrapper trv2-cs',
                                        }}
                                    />
                                    <FormDropdown
                                        label='Value'
                                        options={YValueOptions}
                                        input={{
                                            value: YValue,
                                            onChange: setYValue,
                                            placeholder: 'Choose...',
                                        }}
                                        cacheKey='transaction-view-value'
                                        extStyles={{
                                            container: 'frcsbWrapper trv2-cs',
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
                <div className='chart_container'>
                    <ComponentLoader visible={isLoading || isfetching} />
                    {
                        canRenderChartsAndTable
                            ? (
                                <React.Fragment>

                                    {creditDebitTrendsEnable
                                        && (
                                            <ExpandableCard
                                                extClasses='trv2-expand-card mb-30 pb-20'
                                                isExpanded={isExpanded}
                                                setIsExpanded={toggleExpand}
                                                heading='Credit X Debit Trends'
                                            >
                                                <DebitCreditChart
                                                    yValue={YValue.value}
                                                    onboardingDate={onboardingDate}
                                                    aggregation={Aggregation.value}
                                                    data={data}
                                                    renderingEvent={renderingEvent}
                                                    metricsEvent={transactionViewEvent.events}
                                                    transactionViewEventState={transactionViewEventState}
                                                    fireTvPerformanceMetricEvent={fireTvPerformanceMetricEvent}
                                                />
                                            </ExpandableCard>
                                        )}

                                    {keyTransactionTrendsEnable
                                        && (
                                            <div id='key-transaction-trends' ref={keyTransactionTrendsChartRef}>
                                                <ExpandableCard
                                                    extClasses='trv2-expand-card mb-30 pb-20'
                                                    isExpanded={isExpandKeyTranTrends}
                                                    setIsExpanded={toggleExpandKeyTranTrends}
                                                    heading='Key Transaction Trends'
                                                >
                                                    <div style={{ flexDirection: 'column' }} className='frccWrapper mt-20'>
                                                        <FormDropdown
                                                            label='Credit/Debit'
                                                            options={cdTypeOptions}
                                                            input={{
                                                                value: cdType,
                                                                onChange: setCdType,
                                                                placeholder: 'Choose...',
                                                            }}
                                                            cacheKey='transaction-view-credit-debit'
                                                            extStyles={{
                                                                container: 'frcsbWrapper trv2-cs mb-30',
                                                            }}
                                                        />

                                                        {isExpandKeyTranTrends
                                                            ? (
                                                                <KeyTransactionTrends
                                                                    yValue={YValue.value}
                                                                    aggregation={Aggregation.value}
                                                                    data={data}
                                                                    cdType={cdType.value}
                                                                    onboardingDate={onboardingDate}
                                                                />
                                                            )
                                                            : null}
                                                    </div>
                                                </ExpandableCard>
                                            </div>
                                        )}
                                    {transactionHistoryTableEnable
                                        && (
                                            <div ref={transactionTableRef} id='transaction-history-table'>
                                                <ExpandableCard
                                                    extClasses='trv2-expand-card mb-30'
                                                    isExpanded={isExpandTransHistory}
                                                    setIsExpanded={toggleExpandTranHistory}
                                                    heading='Transaction History'
                                                >
                                                    <TransactionTable data={data} fetching={isfetching} />
                                                </ExpandableCard>
                                            </div>
                                        )}

                                    <div id='annotation-txn-form'>
                                        <ExpandableCard
                                            extClasses='trv2-expand-card mb-30'
                                            isExpanded={trendAnnotationVisibility}
                                            setIsExpanded={toggleExpandTrendAnnotation}
                                            heading='Transaction Trend Annotations'
                                        >
                                            <AnnotationFormUi
                                                flowType={ANNOTATION_MAP.TYPE.TRANSACTION_PAGE_BOTTOM}
                                                extClass='annotations-form-transaction-page-bottom'
                                            />
                                        </ExpandableCard>
                                    </div>
                                </React.Fragment>
                            ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default TransactionViewV2;
