/**
 * @file Loans Out Call - Component to show the loans out call page with table and sidebar with the following tabs
 * 1. User Profile Info
 * 2. Dev Actions
 * 3. Loan Details
 * 4. Ticket History
 */

import React, { useEffect } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Button, LoaderOrError, Table, VerticalNavbar } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    LOANS_OUT_CALL_DEV_ACTIONS_PATH,
    LOANS_OUT_CALL_LOAN_DETAILS_PATH,
    LOANS_OUT_CALL_TICKET_HISTORY_PATH,
    LOANS_OUT_CALL_USER_PROFILE_INFO_PATH,
} from '../App/routes';

import { getLoansOutCallPreFilledValuesObj, LOANS_OUT_CALL_DEV_ACTION_TYPES, LOANS_OUT_CALL_KEY } from './constants';
import { getLoansOutCallTickets, setLoansOutCallTicketId } from './actions';
import { selectLoansOutCallInfo, selectLoansOutCallTicketId } from './selectors';
import TicketHistory from './TicketHistory';
import DevActions from '../DevActions';
import LoanDetails from './LoanDetails';
import UserProfileInfo from './UserProfileInfo';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

const getLoansOutCallSidebarConstants = ({ ticketId } = {}) => [
    {
        id: 'loan-details',
        path: LOANS_OUT_CALL_LOAN_DETAILS_PATH,
        label: 'Loan Details',
        component: LoanDetails,
    },
    {
        id: 'user-profile-info',
        path: LOANS_OUT_CALL_USER_PROFILE_INFO_PATH,
        label: 'User Profile Info',
        component: UserProfileInfo,
    },
    {
        id: 'ticket-history',
        path: LOANS_OUT_CALL_TICKET_HISTORY_PATH,
        label: 'Ticket History',
        component: TicketHistory,
    },
    {
        id: 'dev-actions',
        path: LOANS_OUT_CALL_DEV_ACTIONS_PATH,
        label: 'Agent Actions',
        component: () => (
            <DevActions
                hideNav
                containerKey='loans-out-call'
                actionTypes={LOANS_OUT_CALL_DEV_ACTION_TYPES}
                preFilledValues={getLoansOutCallPreFilledValuesObj(ticketId)}
                toastPosition='top-center'
            />
        ),
    },
];

const RenderTicketDetails = () => {
    const dispatch = useDispatch();

    const ticketId = useSelector(selectLoansOutCallTicketId, shallowEqual);

    return (
        <div className='frWrapper' style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
            <VerticalNavbar navItems={getLoansOutCallSidebarConstants()} />
            <div className='loans-out-call-cont' style={{ height: '100%' }}>
                <Switch>
                    {
                        getLoansOutCallSidebarConstants({ ticketId }).map((item) => (
                            <Route
                                path={item.path}
                                component={item.component}
                            />
                        ))
                    }
                    <Redirect to={LOANS_OUT_CALL_LOAN_DETAILS_PATH} />
                </Switch>
            </div>
            <div className='p-20'>
                <Button
                    v2
                    primary
                    label='Back'
                    onClick={() => {
                        dispatch(setLoansOutCallTicketId({ data: '', key: 'ticketId' }));
                        dispatch(getLoansOutCallTickets());
                    }}
                />
            </div>
        </div>
    );
};

const LoansOutCall = () => {
    const dispatch = useDispatch();

    const {
        ticketId, loading, err, ticketsTable,
    } = useSelector(selectLoansOutCallInfo, shallowEqual);

    useEffect(() => {
        if (Object.keys(ticketsTable).length === 0) {
            dispatch(getLoansOutCallTickets());
        }
    }, []);

    const renderActions = (item) => (
        <div className='frccWrapper' style={{ height: '100%' }}>
            <Button
                link
                label='View Ticket'
                onClick={() => {
                    dispatch(setLoansOutCallTicketId({ data: item.meta, key: 'ticketId' }));
                }}
            />
        </div>
    );

    const paginatedClickHandler = (item) => {
        const { prev, next } = item || {};
        dispatch(getLoansOutCallTickets({
            prevToken: prev, nextToken: next,
        }));
    };

    if (!ticketId) {
        return (
            <div className='loans-out-call-cont p-30'>
                <Table
                    v2
                    rowDataVersion={2}
                    header={ticketsTable?.tableName}
                    labelData={ticketsTable?.columnData}
                    contentData={ticketsTable?.rowData}
                    prevToken={ticketsTable?.prevToken}
                    nextToken={ticketsTable?.nextToken}
                    paginatedClickHandler={paginatedClickHandler}
                    renderActionableColumn={renderActions}
                    extClasses={{
                        outerContainer: 'loans-out-call-table my-30',
                    }}
                />
                <LoaderOrError loading={loading} errorLabel={err} />
            </div>
        );
    }
    return <RenderTicketDetails />;
};

const withReducer = injectReducer({ key: LOANS_OUT_CALL_KEY, reducer });
const withSaga = injectSaga({ key: LOANS_OUT_CALL_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(LoansOutCall);
