/**
 * @file TicketHistory - Component to show the past ticket history od the loan out call ticket user
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { LoaderOrError } from '../../../components';

import { selectLoansOutCallUserTicketHistory, selectLoansOutCallTicketId } from '../selectors';
import { getLoansOutCallUserTicketHistory } from '../actions';
import RenderComponent from '../RenderComponent';

const TicketHistory = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const userTicketHistory = useSelector(selectLoansOutCallUserTicketHistory, shallowEqual);
    const ticketId = useSelector(selectLoansOutCallTicketId, shallowEqual);

    const {
        loading, data: userTicketHistoryData, err,
    } = userTicketHistory?.[ticketId] || {};

    useEffect(() => {
        dispatch(getLoansOutCallUserTicketHistory({
            ticketId, screenKey: ['userTicketHistory', ticketId],
        }));
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className='p-20' style={{ width: '100%', maxWidth: '1000px' }}>
                {
                    userTicketHistoryData && Array.isArray(userTicketHistoryData) && userTicketHistoryData.length > 0 && (
                        <React.Fragment>
                            {
                                userTicketHistoryData.map((component) => (
                                    <RenderComponent
                                        component={component}
                                        tableErrLabel={err}
                                    />
                                ))
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <LoaderOrError
                loading={loading}
                errorLabel={err}
            />
        </React.Fragment>
    );
};

export default TicketHistory;
