import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '../../../../components';
import { UserDetailsContext } from '../../../../context';
import { RudderEvent, pushRudderEvent } from '../../../../rudderEvents';

import { setVKYCQuestionAnswers, logStageProgress } from '../../actions';
import CorrectWrongIcon from '../../../../components/CorrectWrongIcon';
import { QUESTION_ANSWER_VALUE } from '../../constants';

const constructAnswersMap = (answers) => {
    try {
        const map = {};
        Object.entries(answers).forEach(([, question]) => {
            map[question?.question] = !!question?.accepted;
        });
        return map;
    } catch (err) {
        return {};
    }
};

const QuestionStep = ({
    data, meetingId, setAllAnsweredYes, answers,
}) => {
    const dispatch = useDispatch();

    const answersMap = constructAnswersMap(answers);
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const handleAnswer = (question, index, answer) => {
        dispatch(setVKYCQuestionAnswers({
            question: question?.question,
            questionType: data?.stages?.[0]?.question_answer?.questions[index]?.question_type,
            stringAnswer: data?.stages?.[0]?.question_answer?.questions[index]?.expected_answer,
            accepted: answer === 'Yes',
            meetingId,
        }));

        setAllAnsweredYes(true);
    };
    const maskSensitiveData = () => {
        const questions = data?.stages?.[0]?.question_answer?.questions || [];
        return questions.map((question) => ({
            ...question,
            expected_answer: '***',
        }));
    };

    const logUserEvent = (eventName, currentData) => {
        dispatch(logStageProgress({
            message: eventName,
            currentData,
        }));
    };

    useEffect(() => {
        logUserEvent('Question step mounted', maskSensitiveData());
        pushRudderEvent(RudderEvent.VKYC.VKYCQuestionStepLoaded, agentInfo.emailId, {
            MeetingId: meetingId,
            stageName: data?.stage_name,
        });
    }, []);

    if (!data?.stages?.[0].question_answer) {
        logUserEvent('Question step data invalid', data);
        return (
            <div className='vkyc-location-container'>
                <div>No data available</div>
            </div>
        );
    }

    return (
        <div>
            {data?.stages?.[0].question_answer?.questions.map((question, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className='vkyc-step-container'>
                    <div className='frcsbWrapper'>
                        <div className=''>
                            <div className='sop-step-no'>Question {index + 1}</div>
                            <div className='sop-step-title'>{question?.question}</div>
                            <div className='sop-step-des'>{question?.expected_answer}</div>
                        </div>
                        <div className=''>
                            {!!answersMap[question?.question] && <CorrectWrongIcon isCorrect={answersMap[question?.question]} />}
                        </div>
                    </div>

                    <div className='frWrapper mt-20'>
                        <Button
                            v2
                            label={QUESTION_ANSWER_VALUE.YES}
                            onClick={() => {
                                handleAnswer(question, index, QUESTION_ANSWER_VALUE.YES);
                                pushRudderEvent(RudderEvent.VKYC.YesCTAClicked, agentInfo.emailId, {
                                    meetingId,
                                    stageName: data?.stage_name,
                                });
                            }}
                            extClasses={`${answersMap[question?.question] ? 'vkyc-button-active' : 'vkyc-button-inactive'} 
                            ${answersMap[question?.question] === undefined && 'non-interacted'}`}
                        />
                        <Button
                            v2
                            label={QUESTION_ANSWER_VALUE.NO}
                            onClick={() => {
                                handleAnswer(question, index, QUESTION_ANSWER_VALUE.NO);
                                pushRudderEvent(RudderEvent.VKYC.NoCTAClicked, agentInfo.emailId, {
                                    meetingId,
                                    stageName: data?.stage_name,
                                });
                            }}
                            extClasses={`${!answersMap[question?.question] ? 'vkyc-button-active' : 'vkyc-button-inactive'} 
                            ${answersMap[question?.question] === undefined && 'vkyc-button-non-interacted'}`}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default QuestionStep;
