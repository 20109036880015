import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../components/Button';
import { UserDetailsContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { REMARK_LABEL_VALUE_MAP, VKYC_STAGES } from '../constants';

// Custom styles for the modal
const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1024,
    },
    content: {
        top: '8%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 32px 24px',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '80%',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '80%',
        backgroundColor: '#ECEEF0',
        borderRadius: 19,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

const Remarks = (props) => {
    const {
        visible,
        meetingId,
        currentStep,
        currentSubStep,
        onCancel,
        onSubmit,
        submitText,
        setIsAddRemarkVisible,
    } = props;

    const [remarks, setRemarks] = useState();

    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const remarksData = REMARK_LABEL_VALUE_MAP[`${currentStep}-${currentSubStep || ''}`];

    const handleRemarkChange = (value, remark) => {
        if (currentStep !== VKYC_STAGES.VKYC_REPORT) {
            setRemarks((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        [value]: remark,
                    };
                }
                return {
                    [value]: remark,
                };
            });
        } else {
            setRemarks(remark);
        }
    };

    useEffect(() => {
        if (setIsAddRemarkVisible) {
            if (remarksData
                && Array.isArray(remarksData)
                && remarksData.length > 0) {
                setIsAddRemarkVisible(true);
            } else {
                setIsAddRemarkVisible(false);
            }
        }
    }, [currentStep, currentSubStep]);

    useEffect(() => {
        if (currentStep === VKYC_STAGES.VKYC_REPORT) {
            pushRudderEvent(RudderEvent.VKYC.VKYCAgentApprovalRemarksLoaded, agentInfo.emailId, {
                MeetingId: meetingId,
                currentStep,
            });
        } else {
            pushRudderEvent(RudderEvent.VKYC.VKYCCallRemarksLoaded, agentInfo.emailId, {
                MeetingId: meetingId,
                currentStep,
            });
        }
    }, []);

    return (

        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='Add Remarks'
        >
            <div className='vkyc-remarks-text-container'>
                {
                    remarksData
                    && Array.isArray(remarksData) ? (
                            remarksData.map(({ label, value }) => (
                                <React.Fragment>
                                    {
                                        label ? (<div className='vkyc-remarks-field-label'>{label}</div>) : null
                                    }
                                    <textarea
                                        className='vkyc-remarks-text-container-textarea'
                                        onChange={(event) => {
                                            const { value: remark } = event.target || {};
                                            handleRemarkChange(value, remark);
                                        }}
                                        value={
                                            currentStep === VKYC_STAGES.VKYC_REPORT ? remarks : (remarks?.[value])
                                        }
                                        placeholder='Enter your remarks here'
                                    />
                                </React.Fragment>
                            ))
                        ) : null
                }
            </div>

            <div className='vkyc-approval-remarks'>
                <Button
                    v2
                    secondary
                    label='Cancel'
                    onClick={() => {
                        pushRudderEvent(RudderEvent.VKYC.CancelCTAClicked, agentInfo.emailId, {
                            MeetingId: meetingId,
                            currentStep,
                        });
                        if (onCancel) onCancel();
                    }}
                />
                <Button
                    primary
                    v2
                    label={submitText}
                    onClick={() => {
                        if (onSubmit) onSubmit(remarks);
                        pushRudderEvent(RudderEvent.VKYC.ApproveCTAClicked, agentInfo.emailId, {
                            MeetingId: meetingId,
                            currentStep,
                        });
                    }}
                />
            </div>
        </Modal>

    );
};

export default Remarks;
